

//---------------------
// 보증보험
//---------------------
const guaranteeRouter = [
    {
        path: "/guarantee/introduction",
        name: "guarantee-introduction",
        component: () => import("../views/guarantee/GuaranteeIntroduction.vue")
    },
    {
        path: "/guarantee/amount",
        name: "guarantee-amount",
        component: () => import("../views/guarantee/GuaranteeAmount.vue")
    },
    {
        path: "/guarantee/information",
        name: "guarantee-information",
        component: () => import("../views/guarantee/GuaranteeInformation.vue")
    },
    {
        path: "/guarantee/complete",
        name: "guarantee-complete",
        component: () => import("../views/guarantee/GuaranteeComplete.vue")
    },
    {
        // path: "/loan-main",
        path: "/guarantee/loan",
        name: "guarantee-loan",
        component: () => import("../views/guarantee/GuaranteeLoan.vue")
    },    
];


export default guaranteeRouter;


import store from '../index.js'
import router from '../../router/index'
import api from '../../utils/api'

export const modules = ({
    store
})

export const state = ({
    _report:{
        detail: [],
        order_seq: '',
        seq: '',
        phone: '',
        main_seq: ''
    },
    _items: {    
        order: [],
        detail: [],       
        report:{},
        previous_report: {},
        user: [],
        report_share: {},
        realtor_info: {},
        report_realtor_share: {} ,
        report_gurantee: false,
        act_score: 0,
        previous_act_score: 0
    },
    _question:{
        kimdongmin: ''
    },
    _realtor_contract: {
        address_gu: '',
        realtor_count: 0,
        report_count: 0,
        contract_count: 0
    }
});


export const mutations = {  
    setReportReset(state){
        state._report.detail = [];
        state._items.order = [];
        state._items.detail = [];
        state._items.report = [];
        state._items.user = [];
    },
    setReportList(state, response){
        state._items.report = response;
    },
    setReportDetail(state, response){
        console.log(state)
        console.log(response)
        state._report.detail = [];
        state._report.detail = response.value.data;
    },
    setReportDetailReset(state){
        state._report.detail = [];
    },
    setOrderDetail(state, item){                
        state._items.detail = [];
        state._items.detail = Object.assign({}, item);
    },
    setOrderDetailReset(state){
        state._items.detail = [];
    },
    setReport(state, item){      
        state._report.detail = [];
        state._report.detail = Object.assign([], item);
    },
    setOrderUserList(state, item){
        state._items.order = [];
        state._items.order = Object.assign([], item);
    },
    setReportPre(state, item){
        state._items.report = item
    },
    setPreviousReportPre(state, item){
        state._items.previous_report = item
    },
    setOrderConfirmDetail(state, item){
        state._items.detail = [];
        state._items.detail = Object.assign([], item);
    },        
    setOrderDetailAssign(state, index){
        state._items.detail = [];       
        state._items.detail.push(Object.assign({}, state._items.order[index]));
    },
    setShareInfo(state, item) {
        state._report.order_seq = item.order_seq;
        state._report.seq = item.seq;
    },
    setSharePhone(state, item) {
        state._report.phone = item;
    },
    setShareMainseq(state, item) {
        state._report.main_seq = item;
    }, 
    setRealtorInfo(state, item) {
        state._items.realtor_info = item
    },
    setReportRealtorShare(state, item) {
        state._items.report_realtor_share = item;
    },
    setKimdongminQuestionSeq(state, item){
        state._question.kimdongmin = item;
    },
    setReportGurantee(state, item) {
        state._items.report_gurantee = item;
    },
    setActScore(state, item) {
        state._items.act_score = item;
    },
    setPreviousActScore(state, item) {
        state._items.previous_act_score = item;
    },
    setRealtorContract(state, items){
        state._realtor_contract.address_gu = items.address_gu;
        state._realtor_contract.realtor_count = items.realtor_count;
        state._realtor_contract.report_count = items.report_count;
        state._realtor_contract.contract_count = items.contract_count;
    }
};

export const actions = {
    async acReportReset({commit}){
        commit('setReportReset');
    },
    acShareInfo({ commit }, item) {
        console.log('itemsss', item);
        commit('setShareInfo', item)
    },
    acSharePhone({ commit }, item) {
        console.log('itemsss', item);
        commit('setSharePhone', item)
    },
    acShareMainseq({ commit }, item) {
        commit('setShareMainseq', item);
    },
    acOrderDetailAssign({ commit }, index){
        commit('setOrderDetailAssign', index)
    }, 
    acKimdongminQuestionSeq({ commit }, item){
        commit('setKimdongminQuestionSeq', item);
    },
    acActScore({ commit }, item) {
        commit('setActScore', item);
    },
    acPreviousActScore({ commit }, item) {
        commit('setPreviousActScore', item);
    },
    acRealtorContract({commit}, items) {
        commit('setRealtorContract', items);
    },
    async acReportDetail({commit}, params){
        const url = `/api/v1/report`;
        try {
            const response = await api.get(url, {params: params});
            commit("setReportDetail", { value: response.data });
            return response;
        } 
        catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                router.push('/');
            }
            commit("setReportDetailReset");
            return error.response.status;
        }
    },
    async acReportList({ commit }){        
        try {
            const url = `/api/v1/report/users`;
            const res = await api.get(url);
            commit('setReportList', res.data.data);
            return res.data;
        }
        catch (error) {
            console.log("acReportList error message", error.message)
            return null;
        }   
    },     
    async acOrderUserList({ commit }){        
        try {
            const url = `/api/v1/order-users`;
            const res = await api.get(url);
            commit('setOrderUserList', res.data.data.items);
            return res.data;
        }
        catch (error) {
            console.log("acOrderUserList error message", error.message);
            if(error.response?.status === 401){
                window.localStorage.removeItem('naezip_to_ac_se');
            }
            return null;
        }   
    },     

    async acReport({ commit }, report_seq){        
        try {
            const url = `/api/v1/report?document=${report_seq}`;
            const res = await api.get(url);
            commit('setReport', res.data.data[0]);
            return res.data.data;
        }
        catch (error) {
            console.log("acCallGet error message", error.message)
            return null;
        }   
    },  
    async acReportCart({ commit }, document){        
        try {
            const url = `/api/v1/report/${document}`;
            const res = await api.get(url);
            commit('setReport', res.data.data);
            return res.data.data[0];
        }
        catch (error) {
            console.log("acCallGet error message", error.message)
            return null;
        }   
    },  
    async acOrderDetail({ commit }, order_seq){        
        try {
            const url = `/api/v1/order-carts?scan=${encodeURIComponent(order_seq)}`;
            const res = await api.get(url);            
            commit('setOrderDetail', res.data?.data)
            return res.data?.code === 0 ? true :false;
        }
        catch (error) {
            console.log("acCallGet error message", error.message)
            commit('setOrderDetailReset');
            return null;
        }      
    },    
    async acOrderDetailShare({ commit }, order_seq){        
        try {
            const url = `/api/v1/order-cart-shares/${order_seq}`;
            const res = await api.get(url);            
            commit('setOrderDetail', res.data?.data);
            return res.data?.data;
        }
        catch (error) {
            return null;
        }      
    },         
    async acReportPre({ commit }, document, realtor_status){        
        try {   
            const url = `/api/v1/report/user/${document}?realtor=${realtor_status ? true : false}`;
            const res = await api.get(url);
            commit('setReportPre', res.data.data);
            return res.data.data;
        }
        catch (error) {
            console.log("acReportPre error message", error.message)
            return null;
        }     
    },
    async acPreviousReportPre({ commit }, document, realtor_status){
        try {
            const url = `/api/v1/report/user/${document}/previous?realtor=${realtor_status ? true : false}`;
            const res = await api.get(url);
            commit('setPreviousReportPre', res.data.data);
            return res.data.data;
        }
        catch (error) {
            console.log("acPreviousReportPre error message", error.message)
            return null;
        }
    },
    async acReportSharePre({ commit }, cart_seq, realtor_status){        
        try {   
            const res = await api.get(`/api/v1/report/user/cart/share?document=${cart_seq}&realtor=${realtor_status ? true : false}`);
            commit('setReportPre', res.data.data);
            return res.data.data[0];
        }
        catch (error) {
            console.log("setReportSharePre error message", error.message)
            return null;
        }     
    },       
    async acOrderConfirmDetail({ commit }, order_seq){        
        try {
            const res = await api.get(`/api/v1/order/admin/${order_seq}`);
            commit('setOrderConfirmDetail', res.data.data)
            return res;
        }
        catch (error) {
            console.log("acOrderConfirmDetail error message", error.message)            
            return null;
        }     
    },   
    async acReportRealtorShare({ commit }, params) {
        const url = `/api/v1/report-realtor/share`;
        try {
            const res = await api.post(url, {...params});
            return res.data;
        } catch(error) {
            return null
        }
    },
    async acUpdateReportRealtorShare({ commit }, params) {
        const url = `/api/v1/report-realtor/share`;
        try {
            const res = await api.patch(url, {...params});
            return res?.data;
        } catch(error) {
            return null
        }
    },
    async acGetReportRealtorShareList({ commit }) {
        const url = `/api/v1/report-realtor/shares`;
        try {
            const res = await api.get(url);
            const final_data = {}

            for (const data of res.data.data) {
                if (!Object.keys(final_data).length) {
                    final_data[data.name] = [{...data}]
                } else if (final_data[data.name].length) {
                    final_data[data.name].push({...data})
                } else {
                    final_data[data.name]  [{...data}]
                }
            }

            commit('setReportRealtorShare', final_data);
            return res.data;
        } catch(error) {
            return null
        }
    },
    async acRealtorInfo({ commit }, params) {
        const url = `/api/v1/report-realtor/reatlor/info?order_seq=${encodeURIComponent(params?.order_seq)}`;
        try {
            const res = await api.get(url);
            return res?.data?.data
        } catch(error) {
            return null
        }
    },
    async acKimdongminQuestion({}, params){
        console.log(params)
        try{
            const url = `/api/v1/report-question/kimdongmin/${params.seq}`;
            const res = await api.post(url,{'email': params.email, 'content': params.content});                   
            return res.data
        }
        catch(err){
            return false;
        }
    },
    async acUpdateReportNote({ commit }, params) {
        const url = `/api/v1/report-note`;
        try {
            const res = await api.patch(url, { ...params });
            return res?.data?.data;
        } catch(error) {
            return null
        }
    },

    async acOrderGuarantee({ commit }, params) {
        const url = `/api/v1/report-guarantee`;
        try {
            const res = await api.post(url, { ...params });
            return res?.data;
        } catch(error) {
            return null
        }
    },

    async acOrderReportGuarantee({ commit }, params) {
        const url = `/api/v1/report-guarantee?cart_seq=${params.cart_seq}`;
        try {
            const res = await api.get(url);
            if (res?.data?.data.guaranteeYn) {
                commit('setReportGurantee', true);
            } else {
                commit('setReportGurantee', false);
            }
            return true;
        } catch(error) {
            return null
        }
    },
    acAdminReportPreview({commit}, item){
        const report = Object.assign({}, item);
        const cart = Object.assign({}, item);
        commit('setOrderDetail', cart.cart);
        delete report.cart;
        commit('setReportPre', report)
    },
    async acUpdateReportRequestData({ commit }, params) {
        const url = `/api/v1/report/user/${params.report_seq}`;
        try {
            const res = await api.patch(url, {...params});
            return res?.data;
        }
        catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                console.log(commit);
            }
            else{
                return false;
            }
        }
    },
    async acBeforePaymentReportSimpleList({commit}, params) {
        const url = `/api/v1/report/simple/before-payment`;
        try {
            const res = await api.get(url, params);
            return res.data;
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
            }
            return error.response.status;
        }
    },
    async acUpdateReportFirstDeposit({ commit }, params) {
        const url = `/api/v1/report/user/${params.report_seq}/first-deposit`;
        try {
            const res = await api.patch(url, {...params});
            return res?.data;
        }
        catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                console.log(commit);
            }
            else{
                return false;
            }
        }
    },
};


export const getters = {

};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
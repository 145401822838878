<template>
    <div class="continue_box" v-if="isCart">
        <div class="box_text">
            <p><strong>전세사기 리포트 신청 중</strong></p>
            <p>주소를 입력해 주세요</p>
        </div>
        <div class="box_btn"> 
            <div @click="fnNext">
                이어하기
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import DeterminedConstant from '@/mixins/order/const/orderDeterminedConstant';
import Constant from '@/mixins/analyze/const/analyzeConstant.js'
export default {
    name: 'NoAddressContinueComponent',
    props:['isCart'],
    data() {
        return {

        }
    },
    computed:{
        ...mapState(['_state']),
    },
    mounted() {

    },
    methods: {
        ...mapActions('orderStore', ['acOrderDeterminedList']), 
        ...mapMutations([Constant.ADD_COMPLETE_ADDRESS_UNSET]),
        ...mapMutations('orderDeterminedStore', [DeterminedConstant.ADD_ORDER_DETERMINED_SEQ]), 
        async fnInit(){
            const result = await this.acOrderDeterminedList();
            if(result){
                if(result.id > 0){
                    this.isCart = true;                
                    this.setDeterminedOrderSeq(result.id);
                    this.setAnalyzeCompleteAddressUnset({
                        address: result.status === 'address' ? '주소미정' : 'complete',
                        building: result.status === 'dongfloorho' ? '주소미정' : 'complete',
                        addressStatus: result.status
                    });   
                }         
                else{
                    this.isCart = false;
                }    
            }
            else{
                this.isCart = false;
            }
       },
       async fnNext(){
            const result = await this.acOrderDeterminedList();
            if(result){
                if(result.id > 0){
                    this.setDeterminedOrderSeq(result.id);
                    this.setAnalyzeCompleteAddressUnset({
                        address: result.status === 'address' ? '주소미정' : 'complete',
                        building: result.status === 'dongfloorho' ? '주소미정' : 'complete',
                        addressStatus: result.status
                    });   
                }         
            }

            this.isCart = false;
            if(this._state.addressDeterminedState.addressStatus === 'address'){
                this.$router.push('/report/waiting/confirm-type');
            }
            else if(this._state.addressDeterminedState.addressStatus === 'dongfloorho'){
                this.$router.push('/report/waiting/confirm-address');
            }
        }
    }
}
</script>

<style>
.continue_box{
    background: #606060;
    padding:20px;
    display: flex;
    justify-content: space-between;
    width:90%;
    height: 80px;
    margin:auto;
    border-radius: 14px;
    margin-bottom: 10px;
    text-align: left;
}
.continue_box .box_text p{
    color:#FFFFFF;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
}
.continue_box .box_text p strong{
    color:#FFFFFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
.continue_box .box_btn > div{
    background: #979797;
    color:#FFFFFF;
    margin: auto;
    padding:10px 12.5px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
}

@media screen and (max-width: 1080px){
    .continue_box{
        margin-bottom: 30px;
    }
}
</style>
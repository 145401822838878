
//---------------------
//SCI
//---------------------
const sciRouter = [
    {
        path: "/creditinfo/agreement",
        name: "SCI-agreement",
        component: () => import("../views/SCI/SCIAgreement.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },

    {
        path: "/creditinfo",
        name: "SCI-component",
        component: () => import("../views/SCI/SCIChoiceRequest.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },

    {
        path: "/creditinfo/requestowner",
        name: "SCI-request-owner",
        component: () => import("../views/SCI/SCIRequestOwner.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },

    {
        path: "/creditinfo/identify-auth",
        name: "SCI-identify-auth",
        component: () => import("../views/SCI/SCIIdentifyAuth.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },

    {
        path: "/creditinfo/sendrequest",
        name: "SCI-send-request-owner",
        component: () => import("../views/SCI/SCIRequestOwnerSendTalk.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },

    {
        path: "/creditinfo/waiting-owner",
        name: "SCI-wait-owner",
        component: () => import("../views/SCI/SCIWaitOwner.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },

    {
        path: "/creditinfo/lessor-auth",
        name: "SCI-lessor-get-auth",
        component: () => import("../views/SCI/lessor/SCILessorGetAuth.vue"),
    },

    {
        path: "/creditinfo/realtor-auth",
        name: "SCI-realtor-get-auth",
        component: () => import("../views/SCI/realtor/SCIRealtorGetAuth.vue"),
    },

    {
        path: "/creditinfo/lessor-auth-step",
        name: "SCI-lessor-auth",
        component: () => import("../views/SCI/lessor/SCILessorAuth.vue"),
    },

    {
        path: "/creditinfo/report",
        name: "SCI-lessor-report",
        component: () => import("../views/SCI/SCIReport.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },

    {
        path: "/creditinfo/bridge-lessor-report",
        name: "SCIBridge-LessorReport",
        component: () => import("../views/SCI/lessor/SCILessorBridge.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },

    {
        path: "/creditinfo/error",
        name: "SCIError",
        component: () => import("../views/SCI/SCIError.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },

    {
        path: "/creditinfo/waiting-lessor",
        name: "SCILessorWaitOwner",
        component: () => import("../views/SCI/lessor/SCILessorWait.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },

    {
        path: "/creditinfo/request-realtor",
        name: "SCIRequestOwner",
        component: () => import("../views/SCI/realtor/SCIRequestRealtor.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },

    {
        path: "/creditinfo/realtor-kakao",
        name: "sci-realtor-kakao",
        component: () => import("../views/SCI/realtor/SCIRealtorKakao.vue"),

    },

    {
        path: "/creditinfo/realtor-login",
        name: "SCIRealtorLogin",
        component: () => import("../views/SCI/realtor/SCIRealtorLogin.vue"),

    },

    {
        path: "/creditinfo/waiting-realtor",
        name: "SCIRealtorWait",
        component: () => import("../views/SCI/realtor/SCIRealtorWait.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },

    {
        path: "/creditinfo/sendtalk-realtor",
        name: "SCIRealtorSendRequest",
        component: () => import("../views/SCI/realtor/SCIRealtorSendRequest.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },

    {
        path: "/creditinfo/realtor-send-lessor",
        name: "SCIRealtorSendTalk",
        component: () => import("../views/SCI/realtor/SCIRealtorSendTalk.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },

    {
        path: "/creditinfo/realtor-login-check",
        name: "SCIRealtorLoginCheck",
        component: () => import("../views/SCI/realtor/SCIRealtorLoginCheck.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },    

    {
        path: "/creditinfo/sci/my",
        name: "creditinfo-sci-my",
        component: () => import("../views/SCI/info/SciMyInfoView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
];


export default sciRouter;

import store from "../../index.js";
import LoanGovConstant from '@/mixins/loan/const/loanGovConstant.js';
import LoanGovAcConstant from '@/mixins/loan/const/loanGovAcConstant.js';
import api from "../../../utils/api.js";

export const modules = {
    store,
};

export const state = {
    _payload: {
        age:'',
        house:'',
        income:'',
        loan_yn:false,
        loan_name:null,
        repayment_yn:false,
        property:null,
        condition:null,         
    },    
    _status:{
        loan:false,
        difficult:null
    },
    _message:null,
    _item:{},
    _loans:[],
    _error:{}
};

export const mutations = {  
    [LoanGovConstant.SET_LOAN_GOV_AGE]: (state, payload) => {
        state._payload.age = payload;
    },
    [LoanGovConstant.SET_LOAN_GOV_HOUSE]: (state, payload) => {
        state._payload.house = payload;
    },
    [LoanGovConstant.SET_LOAN_GOV_INCOME]: (state, payload) => {
        state._payload.income = payload;
    },
    [LoanGovConstant.SET_LOAN_GOV_LOANYN]: (state, payload) => {
        state._payload.loan_yn = payload;
    },
    [LoanGovConstant.SET_LOAN_GOV_LOANNAME]: (state, payload) => {
        state._payload.loan_name = payload;
    },
    [LoanGovConstant.SET_LOAN_GOV_REPAYMENTYN]: (state, payload) => {
        state._payload.repayment_yn = payload;
    },
    [LoanGovConstant.SET_LOAN_GOV_PROPERTY]: (state, payload) => {
        state._payload.property = payload;
    },
    [LoanGovConstant.SET_LOAN_GOV_CONDITION]: (state, payload) => {
        state._payload.condition = payload;
    },
    [LoanGovConstant.SET_LOAN_GOV_STATUS_LOAN]: (state, status) => {
        state._status.loan = status;
    },
    [LoanGovConstant.SET_LOAN_GOV_MESSAGE]: (state, message) => {
        state._message = message;
    },
    [LoanGovConstant.SET_LOAN_GOV_STATUS_DIFFICULT]: (state, status) => {
        state._status.difficult = status;
    },
    [LoanGovConstant.SET_LOAN_GOV]: (state, item) => {
        state._item = {};
        state._item = item;
    },
    [LoanGovConstant.SET_LOAN_GOV_POSSIBLE]: (state, items) => {
        state._loans = [];
        state._loans = items;
    },
    [LoanGovConstant.SET_LOAN_GOV_DELETE_ITEM]: (state) => {
        state._payload = {
            age:'',
            house:'',
            income:'',
            loan_yn:false,
            loan_name:null,
            repayment_yn:false,
            property:null,
            condition:null,     
        };
        state._message = null;
        state._status = {
            loan:false,
            difficult:null
        };
        state._loans = [];
        state._item = {};
        state._error = {};
    },
};

export const actions = {
    async acLoanGoveSave({ commit }, payload) {
        const url = LoanGovAcConstant.POST_LOAN_GOV;
        try {
            const res = await api.post(`${url}`, {...payload});
            if(res.data?.code === 0){
                commit(LoanGovConstant.SET_LOAN_GOV_STATUS_LOAN, true);     
                commit(LoanGovConstant.SET_LOAN_GOV, res.data.data);     
            }
            else{
                commit(LoanGovConstant.SET_LOAN_GOV_STATUS_LOAN, false);
            }
            return res.data?.code === 0;
        } 
        catch(error) {        
            commit(LoanGovConstant.SET_LOAN_ERROR, error.response.data);
            return false;
        }
    }, 
    async acLoanGoveMessage({ commit }, payload) {
        const url = LoanGovAcConstant.POST_LOAN_GOV_MESSAGE;
        try {
            const res = await api.post(`${url}`, {...payload});
            return res.data?.code === 0;
        } 
        catch(error) {                 
            commit(LoanGovConstant.SET_LOAN_ERROR, error.response.data);
            return false;
        }
    }, 
};

export const getters = {
    geCodeFail: state => {
        switch(state._error.code){
            case 0: return '';
        }  
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
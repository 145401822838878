const bridgeLoginApp = {};
bridgeLoginApp.install = function(Vue)
{
    Vue.prototype.$_kakaoLoginToken = function(path, token){
        console.log(token)
        
        if(path === 'fail'){
            this.$router.push('/login/error');
            return false;
        }

        this.$store.dispatch('acRequestWebviewKakaoLogin', token)
        .then(res => {
            if(res){
                const userAgent = navigator.userAgent.toLowerCase();
                console.log('userAgent', userAgent);

                if (userAgent.indexOf('android') !== -1) {
                    window.socialLoginBridge.postMessage(res);                          
                } 
                else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
                    window.webkit.messageHandlers.socialLoginBridge.postMessage(res);
                }    

                switch(path){
                    case 'main': 
                    location.href = window.location.origin;            
                    break;
                    case 'faq': this.$router.push('/analyze/free'); break;
                    case 'tax': this.$router.push('/tax/danger'); break;
                }                
            }
        }) 
        .catch(err => {
            console.log(err);
            this.$router.push('/login/error');
        })
    }

    Vue.prototype.$_refreshToken = function(token){
        console.log(token)
        
        if(!token){
            this.$router.push('/login/error');
            return false;
        }

        // this.$store.dispatch('acWebviewRefreshToken', token)
        // .then(res => {
        //     if(res){
        //         console.log('acWebviewRefreshToken', res);
        //     }
        //     else{
        //         const userAgent = navigator.userAgent.toLowerCase();
        //         console.log('userAgent', userAgent);

        //         if (userAgent.indexOf('android') !== -1) {
        //             window.logoutBridge.postMessage(res);                          
        //         } 
        //         else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
        //             window.webkit.messageHandlers.logoutBridge.postMessage(res);
        //         }       
        //     }
        // })
        // .catch(err => {
        //     console.log(err);
        //     this.$router.push('/login/error');
        // })
    }
}
export default bridgeLoginApp;
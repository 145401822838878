import store from '../index.js';
import api from '../../utils/api';

export const modules = ({
    store
});

export const state = ({

});


export const mutations = {  

};

export const actions = {
    async acAmountFailLog({}, params) {
        const url = `/api/v1/amount-fail-log`;
        console.log('failkfai', params)
        try {
            await api.post(url,{
                'imp_uid': params.imp_uid,
                'merchant_uid': params.merchant_uid,
                'error_code': -9826,
                'error_msg': params.error_msg                
            });                                    
            return null;
        } 
        catch (error) {
            return false;
        }
    }
};


export const getters = {

};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
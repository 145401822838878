// import axios from 'axios';
import Vue from 'vue'
import store from '../index.js'
import api from '../../utils/api'

export const modules = ({
    store
});

export const state = ({
    _cart: {
        info: {}
    },
    _complete: [],
    _error: {
        code: 0,
        message: ''
    }
});


export const mutations = {  
    setOrderCartInfo(state, item) {
            state._cart.info = item
    },
    setCompleteNo(state, items){
        state._complete = items;
    },
    setResetCompleteNo(state){
        state._complete = [];
    },
    setOrderError(state, payload) {
        state._error = payload;
    }
};

export const actions = {
    async acOrderCommit({ commit }, params){
        try {
            console.log('params', params)
            const res = await api.post(`/api/v1/order/complete`, params );
            console.log('acOrderCommit', res)
            if(res.data.code !== 0){
                const url = `/api/v1/amount-fail-log`;
                await api.post(url, {
                    'imp_uid': params.imp_uid,
                    'merchant_uid': params.merchant_uid,
                    'error_code': res.data.code,
                    'error_msg': res.data.message
                });
            }
            if (res.data?.data?.firstPurchase) {
                Vue.prototype.$mixpanel_track('First-time-purchase');
            }

            return res;
        }
        catch (error) {
            console.log("acOrderCommit error message", error)
            if (error.response.status === 401) {
                localStorage.clear();
                console.log(commit)
            } 
            else{
                return false;
            }                      
        }         
    },
    async acIsOrderCoupon({ commit }){        
        try {
            const url = `/api/v1/order-coupons/user`;
            const res = await api.get(url);
            return res.data?.code === 0 && res.data?.data?.status;
        }
        catch (error) {
            console.log(commit);
            return false;
        }   
    },     
    async acIsOrderCouponCheck({ commit }){        
        try {
            const url = `/api/v1/order-coupons/user/confirm`;
            const res = await api.get(url);
            return res.data.message;
        }
        catch (error) {
            console.log("acIsOrderCouponCheck error", error);
            console.log(commit);
            return error.response.data.message;
        }   
    },          
    async acOrderCart({ commit } , order_seq) {
        try {
            const res = await api.get(`/api/v1/order/detail/realtor?order_seq=${encodeURIComponent(order_seq)}`); 
            
            commit('setOrderCartInfo', res.data.data);
            return res.data;   
        }
        catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                console.log(commit)
                return false;
            } 
            else{
                alert(error.response.data.message);
                return false;
            }                      
        }
    },
    async acOrderIrosUpdate({ commit }, params){
        try {
            const res = await api.patch(`/api/v1/order-carts/iros/${encodeURIComponent(params.merchant)}`,{
                'unique_no': params.estate_code,
                'bunji': params.estate_bunji,
                'confirm': params.confirm,
                'address': params.estate_address
            });
            return true;           
        }
        catch (error) {            
            if(error.response.data?.code === 2511){
                alert('수정시간이 초과되었습니다.');
                return true;
            }
            else{
                return false;   
            }                               
        }         
    },
    async acOrderIros({ commit }, params){
        console.log('paramssss', params)
        try {
            const res = await api.get(`/api/v1/order-carts/iros/${encodeURIComponent(params.merchant_uid)}`);
            return true;           
        }
        catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                console.log(commit)
                return false;
            } 
            else{
                alert(error.response.data.message);
                return false;
            }                      
        }         
    },
    async acOnceOrder({ commit }, body) {
        try {
            const url = `/api/v1/order/once-complete`;
            const res = await api.post(url,body);
            return res.data?.code === 0 ? true : false;
        } 
        catch (error) {
            console.log(commit)
            return false;
        }
    }, 

    async acCouponOnceOrder({ commit }, body) {
        try {
            const url = `/api/v1/order/coupon/once-complete`;
            const res = await api.post(url,body);
            return res.data?.code === 0 ? true : false;
        } 
        catch (error) {
            console.log(commit)
            return false;
        }
    }, 

    async acOrderDeterminedList({commit}) {
        const url = `/api/v1/order-determined/recent`;
        try {
            const res = await api.get(url);            
            return res.data.data;
        } catch (error) {
            return false;
        }
    },

    acCompleteNo({commit}, items){
        commit('setCompleteNo', items);
    },
    acResetCompleteNo({commit}){
        commit('setResetCompleteNo');
    },
};


export const getters = {
    geCodeFail: state => {
        switch(state._error.code) {
            case 2000: return '인증에 실패했습니다. 다시 시도해 주세요.';
            case 2001: return '인증에 실패했습니다. 다시 시도해 주세요.';
            case 2500: return '결제 타입이 올바르지 않습니다.';
            case 2501: return '결제 정보 분석이 진행중입니다. 잠시 후 다시 시도해 주세요.';
            case 2502: return '기존 리포트 승인이 필요합니다.';
            case 2503: return '결제 정보를 찾을 수 없습니다.';
            case 2504: return '결제 요청에 실패했습니다. 다시 시도해 주세요.';
            case 2508: return '결제 인증에 실패했습니다.';
            case 2509: return '결제 정보를 찾을 수 없습니다.';
            case 2510: return '결제가 완료되지 않았습니다.';
            case 2511: return '주소 수정 가능 시간(5분)이 초과되었습니다.';
            case 2512: return '공인중개사는 쿠폰을 사용할 수 없습니다.';
            case 2513: return '주문 정보를 찾을 수 없습니다.';
            case 2514: return '주소 미정 상태가 올바르지 않습니다.';
            case 2515: return '주소지 상태가 올바르지 않습니다.';
            case 2516: return '동/층/호 정보 상태가 올바르지 않습니다.';
            default: return '시스템 오류입니다. 다시 시도해 주세요.';
        }
    }
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
export const alertBase = {
    methods: {
        fnAlert(msg, value = null, title = null) {
            if (!value) {
                this.fnSingle(msg)
            }
            else {
                if (value.alert) {                    
                    this.$store.state._modal.modalState = true;
                    this.$store.state._modal.alertState = true;
                    this.$store.state._modal.msg = msg.replace(/\n/g, "<br />");
                }
                else if(value?.alertTitle){
                    this.$store.state._modal.modalState = true;
                    this.$store.state._modal.alertTitleState = true;
                    this.$store.state._modal.title = title.replace(/\n/g, "<br />");
                    this.$store.state._modal.msg = msg.replace(/\n/g, "<br />");
                }
                else if (value.confirm) {              
                    this.$store.state._modal.modalState = true;
                    this.$store.state._modal.confirmState = true;
                    this.$store.state._modal.msg = msg.replace(/\n/g, "<br />");
                    this.$store.state._modal.event = value.event;
                }
                else if (value.confirm_c) {             

                    this.$store.state._modal.modalState = true;
                    this.$store.state._modal.confirmCancelState = true;
                    this.$store.state._modal.msg = msg.replace(/\n/g, "<br />");
                    this.$store.state._modal.event = value.event;
                    this.$store.state._modal.event02 = value.event02;
                }
                else if(value.login){
                    this.$store.state._modal.modalState = true;
                    this.$store.state._modal.loginState = true;
                    this.$store.state._modal.msg = msg.replace(/\n/g, "<br />");
                    this.$store.state._modal.event = value.event;
                }

                if(value?.btn_cl?.length){
                    this.$store.state._modal.btn_cl = value.btn_cl;
                }
                if(value?.btn_co?.length){
                    this.$store.state._modal.btn_co = value.btn_co;
                }
            }
        },
        fnSingle(msg) {            
            this.$store.state._modal.modalState = true;
            this.$store.state._modal.alertState = true;
            this.$store.state._modal.msg = msg;
        },
        fnAlertClose() {
            this.$store.state._modal.modalState = false;
            this.$store.state._modal.confirmState = false;
            this.$store.state._modal.loginState = false;
            this.$store.state._modal.withdraw = false;
            this.$store.state._modal.msg = '';
            this.$store.state._modal.event = '';
        }
    }
}
// import axios from 'axios';
import store from '../index.js'
import api from "../../utils/api";

export const modules = ({
    store
});

export const state = ({
    _owner_data: []
});


export const mutations = {  
   setOwnerData(state, item) {
    state._owner_data = item
   }
};

export const actions = {
    async acReadOwner({commit}, params) {
        try {
            const url = `/api/v1/report-thecheat?report_seq=${params}`;
            const res = await api.get(url);
            commit('setOwnerData', res.data.data);

            return res.data;
        } catch(error) {
            return false;
        }
    },
    async acSaveOwner({commit}, params) {
        try {
            const url = `/api/v1/report-thecheat`;
            const res = await api.post(url, params);
            return res.data;
        } catch(error) {
            return false;
        }
    }
};


export const getters = {

};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}

import store from '../index.js'
import api from '../../utils/api.js'


export const modules = ({
    store
})

export const state = ({
    _item: {
        "address": '',
    },
    _items: []
});


export const mutations = {  
    setIros(state, item){
        state._item = {};
        state._item = Object.assign({}, item);
    },
    setIrosList(state, items){
        state._items = [];
        state._items = Object.assign([], items);
    },
    setIrosListEmpty(state){
        state._items = [];
    }
};

export const actions = {   
    async acIros({commit}, params){        
        console.log("iros",params)
        try {
            const url = `/api/v1/address-iros?address=${params[0]}&address_jibun=${params[1]}&building_name=${params[2]}&building_dong=${params[3]}&building_ho=${params[4]}&joint_yn=${params[4]}`;
            const res = await api.get(url, {withCredentials: true});

            if(res.data?.data 
                && res.data?.data?.address 
                && res.data?.data?.address !== undefined 
                && res?.data?.data?.address !== 'undefined' 
                && res?.data?.data?.uniqueNo?.length > 0){
                commit('setIros', res.data.data);
                return true;
            }
            else{
                const item = {
                    address: `${params[0]} ${params[2] ? params[2] : ''} ${params[3] ? params[3] : ''} ${params[4] ? params[4] : ''}`,
                    dong:'',
                    type:'',
                    uniqueNo: ''
                }
                commit('setIros', item);
                return false;
            }                        
        }
        catch (error) {
            const item = {
                address: `${params[0]} ${params[2] ? params[2] : ''} ${params[3] ? params[3] : ''} ${params[4] ? params[4] : ''}`,
                dong:'',
                type:'',
                uniqueNo: ''
            }
            commit('setIros', item);            
            console.log("acIros error message", error.message)
            return false;
        }    
    },  
    async acIrosList({ commit }, address){        
        try {
            commit('setIrosListEmpty');
            const url = `/api/v1/address-iros/addresses?address=${address}`;
            const res = await api.get(url);

            console.log('acEstateCode', res.data.data)
            commit('setIrosList', res.data.data);
            return true;
        }
        catch (error) {
            commit('setIrosListEmpty');
            return false;
        }       
    },   
};


export const getters = {

};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
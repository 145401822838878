

//---------------------
// 계약데이터
//---------------------
const contractRouter = [
    {
        path: "/report/contract/date",
        name: "report-contract-date",
        component: () => import("../views/report/contract/LoanDataDateView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
    {
        path: "/report/contract/price",
        name: "report-contract-price",
        component: () => import("../views/report/contract/LoanDataPriceView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
    {
        path: "/report/contract/product",
        name: "report-contract-product",
        component: () => import("../views/report/contract/LoanDataProductView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
    {
        path: "/subscribe/contract/date",
        name: "subscribe-contract-date",
        component: () => import("../views/report/contract/LoanDataSubscribeDateView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
    {
        path: "/subscribe/contract/price",
        name: "subscribe-contract-price",
        component: () => import("../views/report/contract/LoanDataSubscribePriceView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
    {
        path: "/subscribe/contract/product",
        name: "subscribe-contract-product",
        component: () => import("../views/report/contract/LoanDataSubscribeProductView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
];


export default contractRouter;

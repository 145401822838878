export default {
    SET_LOAN_GOV_AGE:'setLoanGovAge',
    SET_LOAN_GOV_HOUSE:'setLoanGovHouse',
    SET_LOAN_GOV_INCOME:'setLoanGovIncome',
    SET_LOAN_GOV_LOANYN:'setLoanGovLoanYn',
    SET_LOAN_GOV_LOANNAME:'setLoanGovLoanName',
    SET_LOAN_GOV_REPAYMENTYN:'setLoanGovRepaymentYn',
    SET_LOAN_GOV_PROPERTY:'setLoanGovProperty',
    SET_LOAN_GOV_CONDITION:'setLoanGovCondition',   
    SET_LOAN_GOV_STATUS_LOAN:'setLoanGovStatusLoan', 
    SET_LOAN_GOV_STATUS_DIFFICULT:'setLoanGovStatusDifficult', 
    SET_LOAN_GOV_MESSAGE:'setLoanGovMessage', 
    SET_LOAN_GOV_DELETE_ITEM:'setLoanGovDeleteItem',    
    SET_LOAN_GOV:'setLoanGov',
    SET_LOAN_GOV_POSSIBLE:'setLoanGovPossible',
    SET_LOAN_ERROR: 'setLoanError',
}

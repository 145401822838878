import store from '../index.js';
import api from '../../utils/api';

export const modules = ({
    store
});

export const state = ({
    _items:[],
    _total:0,
    _default:{}
});


export const mutations = {  
    setReportAmountReview(state, items){
        state._items = items && items?.length ? items : [];    
    },
    setReportSimpleCount(state, item){
        state._total = item.total;
        state._default = item.review;
    }
};

export const actions = {
    async acReportAmountReview({commit}) {
        const url = `/api/v1/report-review`;
        try {
            const result = await api.get(url);  
            commit('setReportAmountReview', result?.data?.data);
        } 
        catch (error) {
            commit('setReportAmountReview', null);
            return null;
        }
    },
    async acSimpleReportCount({commit}) {
        const url = `/api/v1/report-review/report-simples`;
        try {
            const result = await api.get(url);  
            commit('setReportSimpleCount', result?.data?.data);
        } 
        catch (error) {
            commit('setReportSimpleCount', null);
            return null;
        }
    },
};


export const getters = {

};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
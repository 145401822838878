import store from '../index.js'
import api from '../../utils/api'

export const modules = ({
    store
});

export const state = ({
 
});


export const mutations = {  
   
};

export const actions = {
    async acCertifiedInit(){
        try {
            const res = await api.get(`/api/v1/payple/info`);
            return res.data.data;
        }
        catch (error) {
            return null;
        }        
    },    
    async acCertified({ commit }, params){
        try {
            const res = await api.post(`/api/v1/payple`, params);
            console.log('acCertified', res)        
            return res.data.data;
        }
        catch (error) {
            console.log("acCertified error message", error)
            if (error.response.status === 401) {
                localStorage.clear();
                console.log(commit)
                return 401;
            } 
            else{
                return null;
            }                      
        }         
    },
    async acSubscribeInit({ commit }, params){
        console.log('acSubscribeInit param', params)
        try {
            const res = await api.post(`/api/v1/subscribe/payment`, params);
            console.log('acSubscribeInit', res)        
            return true
        }
        catch (error) {
            console.log("acSubscribeInit error message", error.message)
            if (error.response.status === 401) {
                localStorage.clear();
                console.log(commit)
                return 401;
            } 
            else{
                return false;
            }                      
        }         
    },
    async acSubscribePaymentInit({ commit }, params){    
        try {
            const res = await api.post(`/api/v1/subscribe/payment`, params);  
            return true;
        }
        catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                console.log(commit)
                return 401;
            } 
            else{
                alert(error.response.data.code === 6010 ? '해당 주소지로 구독중인 알리미가 있습니다!' : error.response.data.message);
                return false;
            }                      
        }         
    },
    async acSubscribeDate({ commit }, params){
        console.log('acSubscribeDate param', params)
        try {
            const res = await api.post(`/api/v1/subscribe/payment/date`, params);
            console.log('acSubscribeDate', res)        
            return true
        }
        catch (error) {
            console.log("acSubscribeDate error message", error)
            if (error.response.status === 401) {
                localStorage.clear();
                console.log(commit)            
            } 
            else{
                return false;
            }                      
        }         
    },     
    async acFreeInitCheck({ commit }, params){
        console.log('acSubscribeDate param', params)
        try {
            const res = await api.post(`/api/v1/payple/payment/complete-free`, params);
            return res.data.code === 0;
        }
        catch (error) {
            return false;                   
        }         
    }    
};


export const getters = {

};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
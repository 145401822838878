//---------------------
//이용권, 쿠폰
//---------------------
const couponRouter = [
    {
        path: "/ticket/info",
        name: "ticket-info",
        component: () => import("../views/order/ticket/TicketInfo.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },      
    {
        path: "/ticket/price",
        name: "ticket-price",
        component: () => import("../views/order/ticket/TicketPrice.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },      
    {
        path: "/ticket/price-b",
        name: "ticket-price-b",
        component: () => import("../views/a-b/TicketPrice-B.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },      
    {
        path: "/ticket/price-complete",
        name: "ticket-price-complete",
        component: () => import("../views/order/ticket/TicketPriceComplete.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },    
];

export default couponRouter;
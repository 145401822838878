export default {
    ADD_ORDER_DETERMINED_TYPE: "setDeterminedType",
    ADD_ORDER_DETERMINED_PRICE: "setDeterminedPrice",
    ADD_ORDER_DETERMINED_DATE: "setDeterminedDate",
    ADD_ORDER_DETERMINED_ADDRESS: "setDeterminedAddress",
    ADD_ORDER_DETERMINED_BUILDING: "setDeterminedBuilding",
    ADD_ORDER_DETERMINED_SEQ: "setDeterminedOrderSeq",
    ADD_DETERMINED_ERROR: 'setDeterminedError',    
    DELETE_DETERMINED: 'setDeterminedRemove'
}

import store from "../../index.js";
import api from "../../../utils/api";
import UserConstant from '../../../mixins/user/const/userConstant.js';
import UserAcConstant from '../../../mixins/user/const/userAcConstant.js';

export const modules = {
    store,
};

export const state = {
    _item: {},
    _error:{}
};

export const mutations = {  
    [UserConstant.ADD_INVITE_INFO]: (state, payload) => {
        state._item = {code:'', ncode:'', periodCount:0, friendCount:0};
        state._item = payload;
    },
    [UserConstant.ADD_INVITE_ERROR]: (state, payload) => {
        state._error = {code:0, message:''};
        state._error = payload;
    },
};

export const actions = {
    async acInviteInfo({ commit }, payload) {
        const url = UserAcConstant.GET_INVITE_INFO;
        try {
            const res = await api.get(url, {params:payload});
            commit(UserConstant.ADD_INVITE_INFO, res?.data?.data);
        } 
        catch(error) {
            return null
        }
    },
    async acInviteCode({ commit }, payload) {
        const url = UserAcConstant.POST_INVITE_CODE;
        try {
            const res = await api.post(url, {...payload});
            return res?.data?.data?.code ?? null;
        } 
        catch(error) {
            commit(UserConstant.ADD_INVITE_ERROR, error.response.data);
            return false;
        }
    },
    async acInviteCodeUserName({ commit }, payload) {
        const url = UserAcConstant.POST_INVITE_USER_CODE_NAME;
        try {
            const res = await api.post(url, {...payload});   
            return res?.data?.data?.name ?? null;         
        } 
        catch(error) {
            return null
        }
    },
};

export const getters = {
    geCodeFail: state => {
        switch(state._error.code){
            case 1100: return '등록되지 않은 코드입니다.';
            case 1101: return '등록되지 않은 코드입니다.';
            case 1102: return '유효기간이 만료된 코드입니다.';
            case 1103: return '등록할수 없는 코드입니다.';
            case 1104: return '이미 사용한 코드입니다.';
        }    
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
import store from '../index.js'
import api from "../../utils/api";

export const modules = ({
    store
});

export const state = ({
  _sci: {},
  _request: {},
  _agreement: {},
  _ownerReport: {},
  _authResult: {},
  _lessorName: {},
  _waitingType:'',
  _requestStart: {},
  _lessorReportStatus: '',
  _ownerReportStatus: '',
  _realtorReportStatus :'',
  _lessorInfo: {},
  _infoCommit: '',
  _lessor: [],
  _sciList:[],
  _lessors: [],
  _items:{
    id: 0,
    owners: []
  },
  _credit: {
    lessorReport: [],
    userReport: {},
    userLessorReport: {},
    realtorReportStatus:'',
  },
  _status:{
    reportStatus: ''
  },
  _sciAuthInfo: {},
  _realtor: {
    realtorKakao: '',
    owners: [],
    id: 0,
    mobile: [],
  },
  _my:{
    items:[]
  }
});


export const mutations = {  
  setSciUser(state, item) {
      state._sci = item
  },
  setSciReport(state, item){
    state._sci = {};
    state._sci = item;
  },
  setSciRequest(state, item) {
    state._request = item ? item : {};
  },
  setSciAgreement(state, item) {
    state._agreement = item
  },
  setSciAuthResult(state, item) {
    state._items.owners = item;
  },
  setSciLessorName(state, item) {
    state._lessorName = item
  },
  setSciWaitingType(state, item) {
    state._waitingType = item
  },
  setSciRequestStart(state, item) {
    state._requestStart = item
  },
  setSciLessorInfo(state, item) {
    state._lessorInfo = item
  },
  setSciInfoCommit(state, item) {
    state._infoCommit = item
  },
  setRequestInfo(state, item) {
    state._requestInfo = item
  },
  setReportSeq(state, item){
    state._items.id = item
  },
  setSciUserReportUser(state, item){
    state._credit.userReport = item
  },
  setSciUserReportLessor(state, item){
    state._credit.lessorReport = item
  },
  setSciRealtorReportLessor(state, item){
    state._credit.lessorReport = item
  },
  setSciRealtorReportUser(state, item){
    state._credit.userReport = item
  },
  setSciRealtorReportStatus(state, item){
    state._credit.realtorReportStatus = item
  },
  setSciList(state, item) {
    state._lessors = item;
    state._sciList = item;
  },
  setSciRealtorList(state, item) {
    state._realtor.realtorList = item;
    state._sciList = item;
  },
  setLessorReportYn(state, item) {
    state._lessorReportStatus = item;
  },
  setRealtorReportYn(state, item) {
    state._realtorReportStatus = item;
  },
  setOwnerReportYn(state, item) {
    state._ownerReportStatus = item;
  },
  setSciRealtorKakao(state, item) {
    state._realtor.realtorKakao = item;
  },
  setRealtorLinkOwners(state, item) {
    state._realtor.owners = item.split(',').map(owner => owner.trim());;
  },
  setRealtorLinkId(state, item) {
    state._realtor.id = item;
  },
  setRealtorInfo(state, item) {
    state._realtor.mobile = []
    state._realtor.mobile.push(item);
  },
 
  setStoreReset(state){
    state._sci = {};
    state._sciList = [];
    state._request = {};
    state._agreement = {};
    state._ownerReport = {};
    state._authResult = {};
    state._lessorName = {};
    state._waitingType = '';
    state._requestStart = {};
    state._lessorReportStatus = '';
    state._ownerReportStatus = '';
    state._lessorInfo = {};
    state._infoCommit = '';
    state._lessor = [];
    state._lessors = [];
    state._items.id = 0;
    state._items.owners = [];
    state._credit.lessorReport = [];
    state._credit.userReport = {};
    state._credit.userLessorReport = {};
    state._status.reportStatus = '';

    state._sciAuthInfo = {};
  },
  setSciAuthInfo(state, item) {
    state._sciAuthInfo = item;
    state._realtorlist = [];
    state._realtor.owners = [];
    state._realtor.mobile = [];
  },
  setMyInfoList(state, items){
    state._my.items = [];
    state._my.items = items;
  }
};

export const actions = {
    async acSciUser({commit}) {
      try {
        const res = await api.get('/user/credit/terms')
        commit('setSciUser', res.data.data);
        console.log("sci",res.data)
        return true
        } catch (error) {
          commit('setStoreReset');
          console.error(error);
        }
  },
  
  async acSciLessors({ commit }) {
      try {
          const url = '/api/v1/credit/report/lessors';
          const res = await api.get(url);
          if(res?.data?.code === 0 && res?.data?.data?.items?.length){
              commit('setSciList', res.data.data.items);   
          }
          else {
            commit('setStoreReset');
            return false;
          }
      } catch(error) {
          commit('setStoreReset');
          return false;
      }
  },

  async acSciRequestReadInfo({ commit }, params) {
    try {
      const res = await api.get(`/api/v1/user/authentication?report_seq=${params.report_seq}&status=${params.status}`);
      if(res?.data?.code === 0){
          commit('setSciAuthInfo', res.data.data?.auth);
          commit('setSciAuthResult', res.data.data.owners);
          return true;
      }
      else{
        commit('setStoreReset');
        return false;
      }
    } catch (error) {
      console.error(error);
      commit('setSciRequest', null);
      commit('setStoreReset');
      return false;
    }
  },

  async acSciRequest({ commit }, id) {
    try {
      const res = await api.get(`/api/v1/user/authentication/info/${encodeURIComponent(id)}`,
      );
        console.log("네네맞아여", res)
        console.log("acSciRequest",res.data)
        if(res?.data?.code === 0){
            commit('setSciRequest', res.data.data);
            return true;
        }
        else{
          commit('setStoreReset');
          return false;
        }
      } catch (error) {
        console.error(error);
        commit('setSciRequest', null);
        commit('setStoreReset');
        return false;
      }
  },
  
  async acSciAgreement({ commit }, params) {
    try {
      const url = `/api/v1/user/credit/terms`;
      const res = await api.post(url, {...params});
      commit('setSciAgreement',res.data );
  
      return res.data;
    } catch (error) {
      console.error(error);
      commit('setStoreReset');
    }
  },

  async acSciAuthResult({ commit }, params) {
    try {      
      const url = `/api/v1/user/authentication/confirm`;      
      const res = await api.post(url, { ...params })
      console.log("aa",res.data.data.owners)
      if(res.data.code === 0 && res.data.data.confirm){
        commit('setSciAuthResult', res.data.data.owners);
        console.log("setSciAuthResult",res)
        return true;
      }
      else{
        if(res.data?.code !== 0){
          commit('setStoreReset');
        }
        return false;
      }      
    } catch (error) {      
      console.error(error);
      commit('setStoreReset');
      return false;
    }
  },

  async acSciLessorName({ commit}, info) {
  
    const url = `/api/v1/credit/lessor?info=${info}`;
    try {
      const res = await api.get(url)
        commit('setSciLessorName', res.data.data);
        console.log("setSciLessorName", res);
        if(res.data?.code !== 0){
          commit('setStoreReset');
        }
      return res;
     
    } catch(error) {
      commit('setStoreReset');
      return false;
    }
  },

  async acSciRequestStart({ commit }, params) {
    console.log("요청시작", params);
    try {
      const url = `/api/v1/credit/user`;
      const res = await api.post(url, { ...params });
      console.log("setSciRequestStart", res)
      if (res.data.code === 0) {
        commit('setSciRequestStart', res.data);
        console.log("setSciRequestStart", res)
        return true
      } else {
        commit('setStoreReset');
         return false
      }

    
    } catch (error) {
      console.error(error);
      commit('setStoreReset');
    }
  },

  async acSciLessorInfo({ commit }, value) {
    try {
      const url = `/api/v1/credit/lessor`;
      const res = await api.post(url, { info: value });
      console.log('res - acSciLessorInfo', res);
      if(res.data.code === 0){
        return res.data;
      }
      else{
        commit('setStoreReset');
      }
      
    } catch (error) {
      console.error(error);
      commit('setStoreReset');
      return false;
    }
  },

  acSciInfoCommit({ commit }, info) {
    commit('setSciInfoCommit', info);
  },

  async acSciLessorReportCheck({ commit }, report_seq) {
      try {
          const res = await api.get(`/api/v1/credit/report/lessor/${encodeURIComponent(report_seq)}`)
          if(res.data.code === 0){
              commit('setSciReport', res.data.data?.lessor);
              commit('setLessorReportYn', res.data.data?.status);
              return true;
          }
          else{
              commit('setStoreReset');
              return false;
          }
      } 
      catch (error) {      
          console.error(error);
          commit('setStoreReset');
          return false;
      }
  },

  async acSciRealtorReportCheck({ commit }, id) {
    try {
      const res = await api.get(`/api/v1/credit/report/realtor/${id}`)
      if (res.data.code === 0) {
        commit('setSciRealtorReportLessor', res.data.data.lessor);
        commit('setSciRealtorReportUser', res.data.data.user);
        commit('setSciRealtorReportStatus', res.data.data.status);
        return true;
      }
      else{
        commit('setStoreReset');
        return false;
      }
    } catch (error) {      
      console.error(error);
      commit('setStoreReset');
      return false;
    }
  },

  // 임차인 -> 임대인
  async acSciUserReport({ commit }, report_seq) {
    try {
      const res = await api.get(`/api/v1/credit/report/lessor/${encodeURIComponent(report_seq)}`)
      if (res.data.code === 0 ) {
        commit('setSciUserReportLessor', res.data.data?.lessor);    
        commit('setLessorReportYn', res.data.data?.status);
        if (res.data?.data?.status === 'waiting') {
          commit('setSciWaitingType', res.data.data?.type);
          commit('setSciLessorName', res.data.data?.mobile);
        }
      } 
      else{
        commit('setStoreReset');
      }
        return res.data;
    } catch (error) {
      console.error(error);
      commit('setStoreReset');
    }
  },

  // 임대인 -> 임차인
  async acSciLessorReport({ commit }, id) {
    try {
      const res = await api.get(`/api/v1/credit/report/user/${id}`)
      if (res.data.code === 0 ) {
        commit('setSciUserReportUser', res.data.data.user);
        commit('setSciUserReportLessor', res.data.data.lessor);
        commit('setOwnerReportYn', res.data.data.status);
      } 
      else{
        commit('setStoreReset');
      }
     
      return res.data;
    } catch (error) {
      commit('setStoreReset');
      console.error(error);
    }
  },

  acRequestInfo ({ commit }, request_info) {
    commit('setRequestInfo', request_info);
  },
    
  acReportSeq ({ commit }, id) {
    commit('setReportSeq', id);
  },
  
  acStoreReset({ commit }){
    commit('setStoreReset');
  },

  async acRealtorInfo({ commit }, params) {
    try {
      const url = `/api/v1/realtor/info`;
      const res = await api.post(url, { ...params });
      console.log("params.mobile", params.mobile)
      commit('setRealtorInfo', params.mobile);
      if (res.data.code === 0) {
        return true
      }
      else {
         return false
      }
      
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async acRealtorLinkCheck({ commit }, info) {
    try {
      const res = await api.get(`/api/v1/credit/realtor?info=${info}`)
      if (res.data.code === 0) {
        commit("setRealtorLinkOwners", res.data.data.owners);
        commit("setRealtorLinkId", res.data.data.id);
        return true
      }
      else {
        commit('setStoreReset');
      }
  
      return res.data;
    } catch (error) {
      commit('setStoreReset');
      console.error(error);
    }
  },

  async acSciRealtorList({ commit }) {
    try {
      const url = '/api/v1/credit/report/realtors';
      const res = await api.get(url);
      commit('setSciRealtorList', res.data.data.items);
    } 
    catch(error) {
      commit('setStoreReset');
      return false;
    }
},
  
  async acSciRealtorKakao({ commit }, credit_realtor) {
    console.log("credit_realtor",credit_realtor)
    try {
      const url = '/api/v1/user/login/kakao';
      const res = await api.get(url,{...credit_realtor});
      commit('setSciRealtorKakao', res);
    } catch(error) {
      commit('setStoreReset');
      return false;
    }
  },

  async acMyInfoList({ commit }) {

    try {
      const url = '/api/v1/credit-info-my';
     
      const res = await api.get(url);      
      commit('setMyInfoList', res.data.data);
    } catch(error) {
      commit('setMyInfoList', []);
      return false;
    }
  },
};




export const getters = {

};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
<template>
    <div class="toastMessage">
        <p class="txt">{{_modal.toastMsg}}</p>
        <!-- 일단 기능 빼고 추후 예정 -->
        <!-- <p class="btnBox"><button type="button" class="retry">재시도</button></p> -->
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: 'ToastMessage',
        computed: {
            ...mapState(['_modal'])
        }
    }
</script>

<style scoped>

.toastMessage {
    margin: 16px;
    padding: 15px 16px;
    position: fixed;
    display: table;
    vertical-align: middle;
    bottom: 0;
    /* left: 0; */
    top: 0;
    right: 0;
    z-index: 100000;
    /* width: calc(100% - 32px); */
    font-size: 12px;
    width: 12%;
    font-weight: bold;
    border-radius: 5px;
    background-color: #5087FF;
}

.toastMessage .txt {
    display: table-cell;
    width: calc(100% - 70px);
    color: #fff;
}

.toastMessage .btnBox {
    display: table-cell;
    vertical-align: middle;
    width: 70px;
}

.toastMessage .retry {
    text-align: right;
    color: #FF1759;
}

@media screen and (max-width: 1080px){
    .toastMessage {
        margin: 16px;
        padding: 15px 16px;
        position: fixed;
        display: table;
        vertical-align: middle;
        bottom: 0;
        left: 0;
        z-index: 100000;
        width: calc(100% - 32px);
        font-size: 12px;
        font-weight: bold;
        border-radius: 5px;
        background-color: #5087FF;
    }

    .toastMessage .txt {
        display: table-cell;
        width: calc(100% - 70px);
        color: #fff;
    }

    .toastMessage .btnBox {
        display: table-cell;
        vertical-align: middle;
        width: 70px;
    }

    .toastMessage .retry {
        text-align: right;
        color: #FF1759;
    }
}
</style>
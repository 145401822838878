
//---------------------
//로그인
//---------------------
const loginRouter = [
    {
        path: "/analyze/login",
        name: "analyze-login",
        component: () => import("../views/AnalyzeLogin.vue"),
    },
    {
        path: "/analyze/apple/login",
        name: "analyze-apple/login",
        component: () => import("../views/AnalyzeAppleLogin.vue"),
    },
    {
        path: "/analyze/apple/login/cancel",
        name: "analyze-apple/login/cancel",
        component: () => import("../views/AnalyzeCancelAppleLogin.vue"),
    }, 
    {
        path: "/login",
        name: "login",
        component: () => import("../views/login/LoginView.vue"),
    },
];

export default loginRouter;

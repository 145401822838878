import store from '../index.js';
import api from '../../utils/api';

export const modules = ({
    store
});

export const state = ({

});


export const mutations = {  

};

export const actions = {
    async acManagerPreviewReport({}, token) {
        const url = `/api/v1/preview-report/manager`;
        try {
            const result = await api.get(url, {
                headers:{
                    'NZSC-P-ENC': token
                }
            });  
            return result?.data?.code === 0 ? result.data.data : null;
        } 
        catch (error) {
            return null;
        }
    },
    async acManagerPreviewSubscribe({}, token) {
        const url = `/api/v1/preview-subscribe/manager`;
        try {
            const result = await api.get(url, {
                headers:{
                    'NZSC-P-ENC': token
                }
            });  
            return result?.data?.code === 0 ? result.data.data : null;
        } 
        catch (error) {
            return null;
        }
    },    
};


export const getters = {

};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
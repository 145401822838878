import store from "../../index.js";
import OrderdeterminedConstant from '@/mixins/order/const/orderDeterminedConstant.js';
import OrderdeterminedAcConstant from '@/mixins/order/const/orderDeterminedAcConstant.js';
import api from "../../../utils/api.js";

export const modules = {
    store,
};

export const state = {
    _items: {
        contract_type: null,
        contract_date:null,
        deposit_amount: 0,
        rental_amount: 0,
        address: null,
        address_jibun: null,
        address_dong: null,
        address_gu: null,        
        address_li: null,
        address_si: null,
        building_code: null,
        building_name: null,
        building_number: null,
        building_dong: null,
        building_floor:null,
        building_ho:null,
        joint_yn:null,
        order_seq:null
    },
    _error:{}
};

export const mutations = {  
    [OrderdeterminedConstant.ADD_ORDER_DETERMINED_SEQ]: (state, payload) => {
        state._items.order_seq = payload;
    },
    [OrderdeterminedConstant.ADD_ORDER_DETERMINED_TYPE]: (state, payload) => {
        state._items.contract_type = payload;
    },
    [OrderdeterminedConstant.ADD_ORDER_DETERMINED_PRICE]: (state, payload) => {
        state._items.deposit_amount = payload.deposit_amount;      
        state._items.rental_amount = payload.rental_amount;      
    },
    [OrderdeterminedConstant.ADD_ORDER_DETERMINED_DATE]: (state, payload) => {
        state._items.contract_date = payload;      
    },
    [OrderdeterminedConstant.ADD_ORDER_DETERMINED_ADDRESS]: (state, payload) => {
        state._items.address = payload.address;     
        state._items.address_jibun = payload.address_jibun;
        state._items.address_dong = payload.address_dong;
        state._items.address_gu = payload.address_gu;
        state._items.address_li = payload.address_li;
        state._items.address_si = payload.address_si;       
        state._items.building_code = payload.building_code;     
        state._items.building_name = payload.building_name;
        state._items.building_number = payload.building_number;
        state._items.joint_yn = payload.joint_yn;
    },
    [OrderdeterminedConstant.ADD_ORDER_DETERMINED_BUILDING]: (state, payload) => {
        state._items.building_dong = payload.building_dong;
        state._items.building_floor = payload.building_floor;
        state._items.building_ho = payload.building_ho;     
    },
    [OrderdeterminedConstant.ADD_DETERMINED_ERROR]: (state, payload) => {
        state._error = {code:0, message:''};
        state._error = payload;
    },
    [OrderdeterminedConstant.DELETE_DETERMINED]: (state) => {
        state._items.contract_type = null,
        state._items.contract_date =null,
        state._items.deposit_amount = 0,
        state._items.rental_amount = 0,
        state._items.address = null,
        state._items.address_jibun = null,
        state._items.address_dong = null,
        state._items.address_gu = null,        
        state._items.address_li = null,
        state._items.address_si = null,
        state._items.building_code = null,
        state._items.building_name = null,
        state._items.building_number = null,
        state._items.building_dong = null,
        state._items.building_floor =null,
        state._items.building_ho =null,
        state._items.joint_yn =null,
        state._items.order_seq =null
    },
};

export const actions = {
    async acOrderDeterminedAddress({ commit }, payload) {
        const url = OrderdeterminedAcConstant.POST_ORDER_DETERMINED_ADDRESS;
        try {
            const {order_seq, ...param} = payload;
            const res = await api.post(`${url}/${order_seq}`, {...param});
            return res?.data?.code === 0 ? true : false;
        } 
        catch(error) {
            commit(OrderdeterminedConstant.ADD_DETERMINED_ERROR, error.response.data);
            return false;
        }
    },
    async acOrderDeterminedBuilding({ commit }, payload) {
        const url = OrderdeterminedAcConstant.POST_ORDER_DETERMINED_BUILDING;
        try {
            const {order_seq, ...param} = payload;
            const res = await api.post(`${url}/${order_seq}`, {...param});
            return res?.data?.code === 0 ? true : false;
        } 
        catch(error) {
            commit(OrderdeterminedConstant.ADD_DETERMINED_ERROR, error.response.data);
            return false;
        }
    },
    async acOrderDeterminedModifyMinute({ commit }, payload) {
        const url = OrderdeterminedAcConstant.POST_ORDER_DETERMINED_MINUTE_MODIFY;
        try {
            const {order_seq, ...param} = payload;
            const res = await api.post(`${url}/${order_seq}`, {...param});
            return res?.data?.code === 0 ? true : false;
        } 
        catch(error) {
            commit(OrderdeterminedConstant.ADD_DETERMINED_ERROR, error.response.data);
            return false;
        }
    },
};

export const getters = {
    geCodeFail: state => {
        switch(state._error.code){
            case 2511: return '수정시간 5분이 지났습니다.';
        }  
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};


//---------------------
// seo
//---------------------
const seoFeedRouter = [
    {
        path: "/feed/home-frauud",
        name: "feed-home-frauud",
        component: () => import("../views/feed/CharterScenario.vue"),
    },
    {
        path: "/feed/loan",
        name: "feed-loan",
        component: () => import("../views/feed/FeedLoan.vue"),
    },
    {
        path: "/feed/loan-landing",
        name: "feed-loan-base",
        component: () => import("../views/feed/FeedLoanTemp.vue"),
    },
    {
        path: "/feed/insurance",
        name: "feed-insurance",
        component: () => import("../views/feed/FeedInsurance.vue"),
    },
    {
        path: "/fraud/landing",
        name: "fraud-landing",
        component: () => import("../views/feed/FraudLanding.vue")
    },
    {
        path: "/feed/alarm",
        name: "feed-alarm",
        component: () => import("../views/feed/FeedAlarm.vue")
    },
];


export default seoFeedRouter;

<template>
    <article class="landing">
      <img src="@/assets/images/pc_logo.svg" class="landing_img">

      <p>내집스캔에서</p>
      <p>안전 진단을 받은 누적 보증금액<span class="highlightPrice">31조 3790억</span></p> 
      
      <div class="s_warrper">  
        
        <swiper class="swiper"  :options="swiperOption">
          <swiper-slide><img src="https://image.kredatalab.com/landing/review_pcLanding_16800.svg"></swiper-slide>
          <swiper-slide><img src="https://image.kredatalab.com/landing/review_pcLanding_11000.svg"></swiper-slide>   
          <swiper-slide><img src="https://image.kredatalab.com/landing/review_pcLanding_1000w.svg"></swiper-slide>
          <swiper-slide><img src="https://image.kredatalab.com/landing/review_pcLanding_5000.svg"></swiper-slide>       
          <swiper-slide><img src="https://image.kredatalab.com/landing/review_pcLanding_23000.svg"></swiper-slide>     
          <swiper-slide><img src="https://image.kredatalab.com/landing/review_pcLanding_18000.svg"></swiper-slide>
        </swiper>  

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> 
    
      </div>
    
  
      <section>
        <img src="@/assets/images/logo_mss.svg">
        <img src="@/assets/images/logo_molit.svg" style="width: 8%;">
        <img src="@/assets/images/logo_krebi.svg" style="width:13%;">
      
      </section> 
         
    </article>
</template>

<script>

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: 'SideArticleReview',
  components: {
      Swiper,
      SwiperSlide,
  },

  data(){
    return {
      width: 0,
            height: 0,
      landing_01: `${process.env.VUE_APP_PUBLIC_CDN}/landing/landing_01.svg`,
      landing_02: `${process.env.VUE_APP_PUBLIC_CDN}/landing/landing_02.svg`,
      landing_03: `${process.env.VUE_APP_PUBLIC_CDN}/landing/landing_03.svg`,

      swiperOption: { 
          slidesPerView: '2', 
          spaceBetween: 20, 
          loop: true, 
          pagination: { 
              el: '.swiper-pagination', 
              clickable: true,
          }, 
          navigation: { 
              nextEl: '.swiper-button-next', 
              prevEl: '.swiper-button-prev' 
          },
          autoplay: {
              delay:3500,
          },
          breakpoints: { 
            1500: { slidesPerView: 3 },
            spaceBetween: 10, 
          }
      }     
    }
  },
  created(){
  },
  mounted() { 
  },
  methods: {
  }
}
</script>
<style>
.landing_img{
  margin:0 calc(25vw - 270px);
}

.landing section {
  margin: 0;
  margin:0 calc(25vw - 270px);
  margin-top: 58.47px;
}

.highlightPrice {
    font-weight: 900;
    font-size: 48.7931px;
    line-height: 89px;
    color: #5188FF;
    margin-left: 24px;
}


.s_warrper {
 max-width: 55%;
 margin:0 calc(25vw - 270px);
 position: relative;

}
.s_warrper .swiper {
  width: 60%;
  margin-top:3vw;
  display: flex;
  justify-content: center;
  
  
  
}

.s_warrper .swiper-container {
  width: 90%;
  display: flex;
  justify-content: center;
}

.s_warrper .swiper-slide  {
  width: 100px;
  object-fit: cover;
  display: flex;
  justify-content: center;

  
}

.s_warrper .swiper-slide img {
  width: 250px;
  object-fit: contain;

}
.s_warrper .swiper-button-prev {
  background: url(../assets/images/icon/icon_landing_prevarrow.svg) no-repeat;
  width: 43px;
  height: 43px;
  background-size: cover; 
  font-size:0;
  margin-top:0;
  transform: translateY(-50%);
  position: absolute;
}

.s_warrper .swiper-button-next {
  background: url(../assets/images/icon/icon_landing_nextarrow.svg) no-repeat;
  width: 43px;
  height: 43px;
  background-size: cover; 
  font-size:0;
  margin-top:0;
  transform: translateY(-50%);
  position: absolute;
}  

.s_warrper .swiper-button-prev:after {
  display: none;
}

.s_warrper .swiper-button-next:after {
  display: none;
}

@media screen and (max-width: 1400px) {
 
}
</style>
// import axios from 'axios';
import store from '../index.js'

export const modules = ({
    store
});

export const state = ({
    _currentPage: ''
});


export const mutations = {  
   setCurrentPage(state, item) {
    state._currentPage = item
    console.log('state._currentPage', state._currentPage);
   }
};

export const actions = {
    acUpdateCurrentPage({commit}, value) {
        commit('setCurrentPage', value);
    }
};


export const getters = {

};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
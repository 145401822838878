
import store from '../index.js'
import router from '../../router/index'
import api from '../../utils/api'
import SubscribeConstant from '../../mixins/subscribe/const/subscribeConstant';


export const modules = ({
    store
})

export const state = ({
    _subscribe: [],
    _comment: {},
    _history:[],
    _cards: [],
    _items: {
        act_score: 0
    },
    _info: {},
    _reservation:[]
});


export const mutations = {  

    [SubscribeConstant.ADD_CANCEL]: (state, payload) => {
        state._reservation = [];
        state._reservation = payload;  
    },
    [SubscribeConstant.ADD_REASON]: (state, payload) => {
        for(let i = 0; i < state._reservation.length; i++){
            state._reservation[i]['remark'] = payload;
        }
    },
    setIsSubscribe(state, response){
        state._subscribe = response;
    }, 
    setSubscribeReset(state){
        state._subscribe = [];
    },
    setIsSubscribeAnalyzeInit(state, items){
        state._subscribe = [];
        if(items){
            state._subscribe = Object.assign([], items);  
        }        
    },     
    setSubscribeAnalyze(state, items){
        state._history = [];
        state._subscribe = items;

        if(items.history?.hasOwnProperty('gapgu') && items.history?.hasOwnProperty('eulgu')){
            let historyArray = [];
            const dateArrayGapgu = items.history.gapgu.map(item => item);
            const dateArrayEulgu = items.history.eulgu.map(item => item);
            historyArray = historyArray.concat(dateArrayGapgu, dateArrayEulgu);

            const yearData = [];
            historyArray.forEach(item => {
                const dateMatch = item?.date?.match(/(\d{4})년(\d{1,2})월(\d{1,2})일/);
                if (dateMatch) {
                    const year = dateMatch[1];
                    const month = dateMatch[2];    
                    const day = dateMatch[3];                                    
                    const existingYearData = yearData?.find(data => data.year === year);                    
                    if (existingYearData) {
                        const existingMonthData = existingYearData?.data?.find(data => data.month === month);
                        existingYearData.data.push({
                            is: existingMonthData ? false : true,
                            month: month,
                            day: day,
                            content: item?.content && item.content !== undefined && item.content !== 'undefined' ? item.content : '',
                            title: item?.title,
                        });
                        
                        existingYearData.data.sort(function(a, b) { 
                            const aa = Number(a.month) + Number(a.day);
                            const bb = Number(b.month) + Number(b.day);
                            return aa < bb ? -1 : aa > bb ? 1 : 0;
                        });

                    } else {
                        yearData.push({
                            year,
                            data: [{
                                is: true,
                                month: month,
                                day: day,
                                content: item?.content && item.content !== undefined && item.content !== 'undefined' ? item.content : '',
                                title: item?.title,
                            }],
                        });
                    }
                }
            });
            state._history = yearData;            
        }                       
    },
    setSubscribeAnalyzeCards(state, items){
        state._cards = [];
        state._cards = items;            
    },
    setActScore(state, item) {
        state._items.act_score = item;
    },
    setSubscribeInfo(state, item) {
        state._info = item;
    }
};

export const actions = {
    async acSubscribeReset({ commit }){
        commit("setSubscribeReset");
    },
    async acPayment({commit}, params){
        const url = `/api/v1/subscribe/payment`;
        try {
            const response = await api.post(url, 
                { ...params }
            );            
            console.log("acPayment response", response);
            console.log(commit)            
            return response;
        } 
        catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();                
                router.push('/');
            }
            return error.response;
        }
    },  
    async acSubscribeReport({ commit }, id){        
        try {
            const url = `/api/v1/subscribe-report?id=${id}`;
            const res = await api.get(url);
            return res.data.data;
        }
        catch (error) {
            return false;
        }
        finally{
            console.log(commit)
        }   
    },       
    async acSubscribeAddress({ commit }){
        try {
            const res = await api.patch(`/api/v1/subscribe`);
            return true
        }
        catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                console.log(commit)            
            } 
            else{
                return false;
            }                      
        }         
    },
    async acIsSubscribeAnalyze({ commit }){        
        try {
            const url = `/api/v1/subscribes`;
            const res = await api.get(url);      
            commit('setIsSubscribeAnalyzeInit', res.data.data); 
            return true;
        }
        catch (error) {
            commit('setIsSubscribeAnalyzeInit', null); 
            return false;
        }           
    },           
    async acIsSubscribeAnalyzeDetail({ commit }, analyze_seq){        
        try {
            const url = `/api/v1/subscribe/analyze?analyze_seq=${analyze_seq}`;
            const res = await api.get(url);
            commit('setIsSubscribeAnalyze', res.data.data[0]);
            return true
        }
        catch (error) {
            return false;
        }           
    },      
    async acSubscribeAnalyzeDate({ commit }, params){
        console.log('acSubscribeAnalyzeDate param', params)
        try {
            const res = await api.post(`/api/v1/subscribe-analyze/date`, params);
            return true
        }
        catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                console.log(commit)            
            } 
            else{
                return false;
            }                      
        }         
    },    
    async acSubscribeAnalyzeDetail({ commit }, analyze_seq) {   
        try {
            const url = `/api/v1/subscribe-analyze/${analyze_seq}`;
            const res = await api.get(url);
            commit('setSubscribeAnalyze', res.data.data);
            return true
        }
        catch (error) {
            return false;
        }           
    }, 
    async acSubscribeAnalyzeCards({ commit }) {   
        try {
            const url = `/api/v1/subscribe-payment/cards`;
            const res = await api.get(url);
            if(res.data?.code === 0){
                commit('setSubscribeAnalyzeCards', res.data.data);
                return true;
            }
            else{
                return false;
            }
        }
        catch (error) {
            return false;
        }           
    }, 
    async acSubscribeAnalyzeUpdateCards({  }, params) {   
        try {
            const url = `/api/v1/subscribe-payment/card`;
            const res = await api.patch(url, params);
            if(res.data?.code === 0){
                return true;
            }
            else{
                return false;
            }
        }
        catch (error) {
            return false;
        }           
    },
    async acSubscribeInfo({commit}) {   
        try {
            const url = `/api/v1/subscribe-info`;
            const res = await api.get(url);
            if(res.data?.code === 0){
                commit('setSubscribeInfo', res.data?.data);
                return true;
            }
            else{
                return false;
            }
        }
        catch (error) {
            return false;
        }           
    },    
    async acSubscribeReservation({commit}, payload){
        try {
            const url = `/api/v1/subscribe-reservation`;
            const res = await api.post(url, payload);
            return res?.data?.code === 0;
        }
        catch (error) {
            return false;
        }    
    },
    acActScore({ commit }, item) {
        commit('setActScore', item);
    },
    acSubscribeAnalyze({commit}, item){
        const analyze = Object.assign({}, item);
        commit('setSubscribeAnalyze', analyze);
    },
};


export const getters = {

};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
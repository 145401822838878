

//---------------------
// 공인중개사
//--------------------- 
const realtorRouter = [
    {
        path: "/realtor/login",
        name: "realtor-login",
        component: () => import("../views/realtor/RealtorLogin.vue"),
    },
    {
      path: "/realtor/signup",
      name: "realtor-signup",
      component: () => import("../views/realtor/RealtorSignup.vue"),
    },
    {
        path: "/realtor/info",
        name: "realtor-info",
        component: () => import("../views/realtor/RealtorInfo.vue"),
    },
    {
      path: "/realtor/signup/info",
      name: "realtor-signup-info",
      component: () => import("../views/realtor/RealtorSignupInfo.vue"),
    },
    {
        path: "/realtor/share",
        name: "realtor-share",
        component: () => import("../views/realtor/RealtorShare.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            } else {
                next('/');
            }
        }, 
    },
    {
        path: "/realtor/lessee",
        name: "realtor-lessee",
        component: () => import("../views/realtor/RealtorLessee.vue"),
    },
    {
        path: "/realtor/password",
        name: "realtor-password",
        component: () => import("../views/realtor/RealtorPassword.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            } else {
                next('/');
            }
        }, 
    },
    {
        path: "/realtor/result/login",
        name: "realtor-result-login",
        component: () => import("../views/realtor/RealtorShareResultLogin.vue"),
    },
    {
        path: "/realtor/fail",
        name: "realtor-fail",
        component: () => import("../views/realtor/RealtorNotFound.vue"),
    },
    {
        path: "/realtor/code/login",
        name: "realtor-code-login",
        component: () => import("../views/realtor/RealtorCodeLogin.vue"),
    },
];


export default realtorRouter;

import store from '../index.js';
import api from '../../utils/api';

export const modules = ({
    store
});

export const state = ({
    _item: {},
    _average: {}
});


export const mutations = {  
    setRealEstatePrice(state, item) {
        state._item = {};
        state._item = item;
    },
    setRealEstateAverage(state, item) {
        state._average = {};
        state._average = item;
    },
};

export const actions = {
    async acRealEstatePrice({commit}, {subscribe, type, page}) {   
        try {
            const url = `/api/v1/subscribe-price/${subscribe}?type=${type}&page=${page}`;
            const res = await api.get(url);
            console.log(res);

            res.data?.code === 0 ? commit('setRealEstatePrice', res.data?.data)
            : commit('setRealEstatePrice', null);
        }
        catch (error) {
            commit('setRealEstatePrice', null);
        }           
    }, 
    async acRealEstateAverage({commit}, subscribe) {   
        try {
            const url = `/api/v1/subscribe-price/average/${subscribe}`;
            const res = await api.get(url);
            console.log(res);

            res.data?.code === 0 ? commit('setRealEstateAverage', res.data?.data)
            : commit('setRealEstateAverage', null);
        }
        catch (error) {
            commit('setRealEstateAverage', null);
        }           
    }, 
};


export const getters = {

};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
import store from "../../index.js";
import api from "../../../utils/api.js";
import SubscribeConstant from '../../../mixins/subscribe/const/subscribeConstant.js';
import SubscribeAcConstant from '../../../mixins/subscribe/const/subscribeAcConstant.js';

export const modules = {
    store,
};

export const state = {
    _item: {},
    _error:{}
};

export const mutations = {  
    [SubscribeConstant.AMOUNT_INVITE_CODE]: (state, payload) => {        
        state._item = {code:'', point: 0};
        state._item = payload;
    },
};

export const actions = {
    async acSubscribePointCode({ commit }, payload) {
        const url = SubscribeAcConstant.GET_SUBSCRIBE_AMOUNT_INVITE_CODE;
        try {
            const res = await api.get(url, {params:payload});
            commit(SubscribeConstant.AMOUNT_INVITE_CODE, res?.data?.data);
        } 
        catch(error) {
            return null
        }
    },
};

export const getters = {

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
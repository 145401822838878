class ChannelService {
  constructor() {
    this.loadScript();

    this.userObject = {
      language: 'ko',
      tags: ['', ''],
      name:'',
      profile: {
        email: '',
        mobileNumber: '',
        reportStatus:'',
        order:'',
      },
      profileOnce: {
        customerType: '',
        registeredAt: ''
      },
      unsubscribeEmail: false,
      unsubscribeTexting: true
    };
  }

  loadScript() {
    (function(){var w=window;if(w.ChannelIO){return w.console.error("ChannelIO script included twice.")}var ch=function(){ch.c(arguments)};ch.q=[];ch.c=function(args){ch.q.push(args)};w.ChannelIO=ch;function l(){if(w.ChannelIOInitialized){return}w.ChannelIOInitialized=true;var s=document.createElement("script");s.type="text/javascript";s.async=true;s.src="https://cdn.channel.io/plugin/ch-plugin-web.js";var x=document.getElementsByTagName("script")[0];if(x.parentNode){x.parentNode.insertBefore(s,x)}}if(document.readyState==="complete"){l()}else{w.addEventListener("DOMContentLoaded",l);w.addEventListener("load",l)}})();
  }

  boot() {
   
    window.ChannelIO('boot', {
      pluginKey: '946f5c1e-1e8e-4c7a-b4e3-eda8f98e054e',
    }, function onBoot(error, user) {
      if (error) {
        console.error(error);
      } else {
        console.log('boot success', user);
        
      }
    });}

  shutdown() {
    window.ChannelIO('shutdown');
  }

  showMessenger() {
    window.ChannelIO('showMessenger');
  }
  
  hideMessenger() {
    window.ChannelIO('hideMessenger');
  }

  openChat(chatId, message) {
    window.ChannelIO('openChat', chatId, message);
  }

  track(eventName, eventProperty) {
    window.ChannelIO('track', eventName, eventProperty);
  }

  onShowMessenger(callback){
    window.ChannelIO('onShowMessenger', callback);
  }
  
  onHideMessenger(callback) {
    window.ChannelIO('onHideMessenger', callback);
  }
  
  onBadgeChanged(callback) {
    window.ChannelIO('onBadgeChanged', callback);
  }

  onChatCreated(callback) {
    window.ChannelIO('onChatCreated', callback);
  }

  onFollowUpChanged(callback) {
    window.ChannelIO('onFollowUpChanged', callback);
  }

  onUrlClicked(callback) {
    window.ChannelIO('onUrlClicked', callback);
  }

  clearCallbacks() {
    window.ChannelIO('clearCallbacks');
  }
  
  updateUser() {
    window.ChannelIO('updateUser', this.userObject, function onUpdateUser(error, user) {
      if (error) {
        console.error(error);
      } else {
        console.log('updateUser success', user);
      }
    });
  }
  

  addTags(tags, callback) {
    window.ChannelIO('addTags', tags, callback);
  }

  removeTags(tags, callback) {
    window.ChannelIO('removeTags', tags, callback);
  }

  setPage(page) {
    window.ChannelIO('setPage', page);
  }

  resetPage() {
    window.ChannelIO('resetPage');
  }

  showChannelButton() {
    window.ChannelIO('showChannelButton');
  }

  hideChannelButton() {
    window.ChannelIO('hideChannelButton');
  }

  setAppearance(appearance) {
    window.ChannelIO('setAppearance', appearance);
  }

  updateUserObject(email, mobile, order_count, report_status) {
      this.userObject.profile.email = email;
      this.userObject.profile.mobileNumber = mobile;
      this.userObject.profile.order = order_count;
      this.userObject.profile.reportStatus = report_status;


      this.updateUser();
      console.log("updateUserObject", this.userObject)
  }

  
}



export default new ChannelService();





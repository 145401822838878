// import axios from 'axios';
import store from '../index.js'
import api from '../../utils/api'

export const modules = ({
    store
});

export const state = ({
    _currentPage: ''
});


export const mutations = {  
   setCurrentPage(state, item) {
    state._currentPage = item
    console.log('state._currentPage', state._currentPage);
   }
};

export const actions = {
    async acCustomerType() {
        const url = `${process.env.VUE_APP_HOST}/api/v1/mixpanel/customer/type`;
        try {
          const res = await api.get(
            url,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("naezip_to_ac_se")}`,
              },
            }
          );
    
          return res?.data?.customer_type;
        } catch(error) {
          return null;
        }
    },
    async acAmountCumulative({ commit }, merchant_uid) {
      const url = `${process.env.VUE_APP_HOST}/api/v1/mixpanel/amount/cumulative?merchant_uid=${merchant_uid}`;
      try {
        console.log('commit', commit);
        const res = await api.get(
          url,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("naezip_to_ac_se")}`,
            },
          }
        );
        
        return res?.data
      } catch(error) {
        return null;
      }
    },

    async acUserMarketingStatus() {
      const url = `${process.env.VUE_APP_HOST}/api/v1/mixpanel/maketing`;
      try {
        const res = await api.get(
          url,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("naezip_to_ac_se")}`,
            },
          }
        );
        console.log('res', res);
        return res?.data?.marketing
      } catch(error) {
        return null;
      }
    },
};


export const getters = {

};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
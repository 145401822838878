import store from '../index.js';
import api from '../../utils/api';

export const modules = ({
    store
});

export const state = ({
    _contract:{
        st:null,
        en:null,
        yn:null,
        bn:null,
        pn:null,
        co:null
    },
    _sqar: 0
});


export const mutations = {  
    setSqar(state, item){
        state._sqar = item;
    },
    setDate(state, items){
        state._contract.st = items.st_date;
        state._contract.en = items.en_date;
    },
    setLoan(state, item){
        state._contract.yn = item;
    },
    setLoanName(state, items){
        state._contract.bn = items.bank_name;
        state._contract.pn = items.loan_name;
        state._contract.co = items.loan_product_code;
    },
    setContractReset(state){
        state._sqar = 0;

        state._contract.st = null;
        state._contract.en = null;
        state._contract.yn = null;
        state._contract.bn = null;
        state._contract.pn = null;
        state._contract.co = null;
    }
};

export const actions = {
    async acReportContract({commit}, params) {
        const url = `/api/v1/contract/report`;
        try {
            const result = await api.post(url,params);  
            if(result?.data?.code === 0){
                commit('setContractReset');
                return true;
            }   
            else{
                return false;
            }             
        } 
        catch (error) {
            return false;
        }
    },
    async acSubscribeContract({commit}, params) {
        const url = `/api/v1/contract/subscribe`;
        try {
            const result = await api.post(url,params);                             
            if(result?.data?.code === 0){
                commit('setContractReset');
                return true;
            }   
            else{
                return false;
            }
        } 
        catch (error) {
            return false;
        }
    },
    async acReportContractConfirm({}, scan) {
        const url = `/api/v1/contract/report/${scan}`;
        try {
            const result = await api.get(url);                                    
            return result?.data?.code;
        } 
        catch (error) {            
            return error.response?.data?.code;
        }
    },
    async acReportContractLoanConfirm({}, scan) {
        const url = `/api/v1/contract/report-loan/${scan}`;
        try {
            const result = await api.get(url);                                    
            return result?.data;
        } 
        catch (error) {            
            return error.response?.data;
        }
    },
    async acSubscribeContractConfirm({}, scan) {
        const url = `/api/v1/contract/subscribe/${scan}`;
        try {
            const result = await api.get(url);                                    
            return result?.data?.code === 0 ? true : false;
        } 
        catch (error) {
            return false;
        }
    },
    acSqar({commit}, item){
        commit('setSqar', item);
    },
    acDate({commit}, items){
        commit('setDate', items);
    },
    acLoan({commit}, item){
        commit('setLoan', item);
    },
    acLoanName({commit}, items){
        commit('setLoanName', items);
    },
    acReset({commit}){
        commit('setContractReset');
    }
};


export const getters = {

};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
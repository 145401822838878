
//---------------------
//결제
//---------------------
const orderRouter = [   
    {
        path: "/minute/address",
        name: "minute-address",
        component: () => import("../views/order/modify/MinuteAddress.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },        
    {
        path: "/minute/search",
        name: "minute-search",
        component: () => import("../views/order/modify/MinuteSearch.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    }, 
];

export default orderRouter;

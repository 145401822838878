

//---------------------
// 이벤트
//---------------------
const eventRouter = [
    
    //---------------------
    //추석 룰렛
    //---------------------
    // {
    //     path: "/event/chuseok-roulette",
    //     name: "event-chuseok-roulette",
    //     component: () => import("../views/event/ChuseokRouletteView.vue"),
    // }, 
];


export default eventRouter;

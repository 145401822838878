import store from "../../index.js";
import SimpleConstant from '../../../mixins/report/const/simpleConstant.js';

export const modules = {
    store,
};

export const state = {
    _items: {report:{}},
};

export const mutations = {  
    [SimpleConstant.ADD_REPORT_DATA]: (state, payload) => {
        state._items.report = {};
        state._items.report = payload;        
    },
    [SimpleConstant.ADD_ACT_SCORE]: (state, payload) => {
        state._items.act_score = payload;  
    },
};

export const actions = {

};

export const getters = {

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
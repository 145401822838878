import store from "../../index.js";
import api from "../../../utils/api.js";
import OrderTicketAcConstant from '@/mixins/order/const/orderTicketAcConstant.js';
import OrderTicketConstant from '@/mixins/order/const/orderTicketConstant.js';

export const modules = {
    store,
};

export const state = {
    _info:{
        amount:0
    },
    _origin:[],
    _items:[],    
};

export const mutations = {  
    [OrderTicketConstant.ADD_INFO_AMOUNT]: (state, payload) => {
        state._info.amount = payload;
    },
    [OrderTicketConstant.ADD_TICKET_DEAL]: (state, payload) => {
        state._origin = [];
        state._items = [];
        state._origin = payload;
        state._items = payload;
    },
    [OrderTicketConstant.SET_TICKET_ALLSTATE]: (state) => {
        state._items = state._origin.map(e => e);
    },
    [OrderTicketConstant.SET_TICKET_ORDERSTATE]: (state) => {
        state._items = state._origin.map(e => {
            if(e.dealType === '결제'){
                return e;
            }
        }).filter(q => q)
    },
    [OrderTicketConstant.SET_TICKET_USESTATE]: (state) => {

        state._items = state._origin.map(e => {
            if(e.dealType === '사용'){
                return e;
            }
        }).filter(q => q)
    },
    [OrderTicketConstant.SET_TICKET_CANCELSTATE]: (state) => {
        state._items = state._origin.map(e => {
            if(e.dealType === '취소'){
                return e;
            }
        }).filter(q => q)
    },
};

export const actions = {
    async acOrderTicketAmount({ commit }, params){        
        const url = OrderTicketAcConstant.GET_ORDER_TICKET_AMOUNT;
        try {
            const res = await api.get(`${url}?lte=${params ?? ''}`);
            if(res.data?.code === 0){
                commit(OrderTicketConstant.ADD_INFO_AMOUNT, res.data?.code === 0 ? res.data.data.amount : 0);
            }            
            return res.data?.code === 0 && res.data.data?.amount > 0;
        }
        catch (error) {            
            return false;
        }   
    },     
    async acOrderTicketDeal({ commit }){        
        const url = OrderTicketAcConstant.GET_ORDER_TICKET_DEAL;
        try {
            const res = await api.get(url);
            console.log('왜없냐', res.data.data)
            if(res.data?.code === 0){
                commit(OrderTicketConstant.ADD_TICKET_DEAL, res.data?.code === 0 ? res.data.data : []);
            }            
            return res.data?.code === 0;
        }
        catch (error) {            
            return false;
        }   
    },     
    async acOrderTicketSave({commit}, payload) {
        const url = OrderTicketAcConstant.POST_ORDER_TICKET;
        try {
            const res = await api.post(url, payload);
            return res.data?.code === 0 ? res.data.data?.merchant_uid : null
        } catch (error) {
            return null;
        }
    },
    async acOrderTicketUse({commit}, payload) {
        const url = OrderTicketAcConstant.POST_ORDER_TICKET_USE;
        try {
            const res = await api.post(`${url}/${payload.id}`, {status:payload.status});
            return res.data?.code === 0;
        } catch (error) {
            return false;
        }
    },
    async acOrderTicketPayment({commit}, payload) {
        const url = OrderTicketAcConstant.POST_ORDER_TICKET_PAYMENT;
        try {
            const res = await api.post(url, payload);
            return res.data?.code === 0 ? res.data.data.merchant_uid : null;
        } catch (error) {
            return false;
        }
    },
};

export const getters = {
    geCodeFail: state => {
        switch(state._error.code){

        }    
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
<template>
    <div>        
        <article class="fixed_content" style="z-index:200">
            <NoAddressContinueComponent v-if="isContinue" :isCart="isCart"/>
            <div :class="[isIOS ? 'ios-margin' : 'nav']">
                <a @click="fnSelect('main')">
                    <img v-if="!_footer.isHome" src="@/assets/images/icon/icon_nav_home.svg"/>
                    <img v-else src="@/assets/images/icon/iconSelect_nav_home.svg"/>
                    <span>홈</span>
                </a>                
                <a @click="fnSelect('analyze')">
                    <img v-if="!_footer.isAnalyze" src="@/assets/images/icon/icon_nav_analyze.svg"/>
                    <img v-else src="@/assets/images/icon/iconSelect_nav_analyze.svg"/>
                    <span>분석받기</span>
                </a>
                <a @click="fnSelect('subscribe')">
                    <img v-if="!_footer.isSubscribe" src="@/assets/images/icon/icon_nav_subscribe.svg"/>
                    <img v-else src="@/assets/images/icon/iconSelect_nav_subscribe.svg"/>
                    <span>변동 알리미</span>
                </a>
                <a @click="fnSelect('mypage')">
                    <img v-if="!_footer.isMypage" src="@/assets/images/icon/icon_nav_mypage.svg"/>
                    <img v-else src="@/assets/images/icon/iconSelect_nav_mypage.svg"/>
                    <span>마이페이지</span>
                </a>
            </div>
        </article>
    </div>
</template>

<script>
import '@/assets/css/common.css'

import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import NoAddressContinueComponent from '@/components/common/NoAddressContinue.vue'
import {alertBase} from '@/mixins/alert.js'
import DeterminedConstant from '@/mixins/order/const/orderDeterminedConstant';
import Constant from '@/mixins/analyze/const/analyzeConstant.js'
import LoanGovConstant from '@/mixins/loan/const/loanGovConstant';

export default {
    name: 'FooterNavigationComponent',
    components:{
        NoAddressContinueComponent
    },
    data() {
        return {
            isAnalyze: false,
            isHome: true,
            isSubscribe: false,
            isCommunity: false,
            isMypage: false,
            isPreview: false,
            imageAnalyze: 'nav_analyze',
            imageHome: 'nav_home',
            imageSubscribe: 'nav_subscribe',
            imageMypage: 'nav_mypage',
            imageCommunity: 'nav_community',
            isRoute: '',

            isValue: '',
            isIOS: '',

            isContinue:true,
            isCart:false
        }
    },
    created() {
        this.fnList();
        this.fnIOS();
    },
    computed: {
        ...mapState(['_items', '_modal', '_footer', '_state'])
    },
    mounted() {
        if (this.$route.query) {
            this._footer.isAnalyze = true
        }
        this.fnContinue();
    },

    methods: {
        ...mapActions(['acCtaBtnState']),
        ...mapActions(['acOrderReset', 'acFooterState']),
        ...mapActions('subscribeStore',['acSubscribeReset']),
        ...mapActions('reportStore', ['acReportReset']),
        ...mapActions('sciStore', ['acStoreReset']),
        ...mapActions('orderStore', ['acOrderDeterminedList']), 
        ...mapMutations([Constant.ADD_COMPLETE_ADDRESS_UNSET]),
        ...mapMutations('orderDeterminedStore', [DeterminedConstant.ADD_ORDER_DETERMINED_SEQ]), 
        ...mapMutations('loanGovStore', [LoanGovConstant.SET_LOAN_GOV_DELETE_ITEM]),

        fnIOS() {
            const userAgent = navigator.userAgent.toLowerCase();
            const isIOS = /iphone|ipad|ipod/.test(userAgent);
            this.isIOS = isIOS;

            return isIOS;
        },
        fnList() {
            this.fnAlertClose();            
            switch (window.location.pathname.split('/')[1]) {
                case 'analyze':
                    this.acFooterState('analyze');
                    break;
                case 'main':
                    this.acFooterState('main');
                    break;
                case 'subscribe':
                    this.acFooterState('subscribe');
                    break;
                case 'mypage':
                    this.acFooterState('mypage');
                    break;
                default:
                    this.acFooterState('');
            }
        },
        fnListSelect(value) {
            switch (value) {
                case 'analyze':
                    this.acFooterState('analyze');
                    break;
                case 'home-loan':
                    this.acFooterState('analyze');
                    break;                    
                case 'main':
                    this.acFooterState('main');
                    break;
                case 'subscribe':
                    this.acFooterState('subscribe');
                    break;
                case 'mypage':
                    this.$mixpanel_track("View Mypage");
                    this.acFooterState('mypage');
                    break;
                default:
                    break;
            }
        },
        fnSelect(value) {            
            if(this.$router.currentRoute.name === 'mypage' && value === 'analyze'){
                this.$mixpanel_track("Click Report-analyzed");
            }
            if (this.$router.currentRoute.name !== value) {
                this.acSubscribeReset();
                this.acReportReset();
                this.acStoreReset();
            }

            if(value === 'analyze'){
                if(sessionStorage.getItem('home-loan')){
                    value = 'home-loan'
                }                
            }

            this.setLoanGovDeleteItem();
            if (this._items.order.length === 0) {
                this.fnListSelect(value);
                if (this.$router.currentRoute.name !== value) {
                    this.$router.push(`/${value}`);
                }
            }
            else {
                const split = window.location.pathname.split('/')[1];
                if(!['analyze','preview','subscribe'].includes(split) || window.location.pathname?.includes('ticket-complete')){
                    this.acOrderReset();
                    this.fnListSelect(value);
                    if (this.$router.currentRoute.name !== value) {
                        this.$router.push(`/${value}`);
                    }
                    return false;
                }

                const item = {
                    event: this.fnPage,
                    alert: false,
                    confirm: true
                }
                this.isRoute = value;
                this.fnAlert("지금까지 입력한 정보가 초기화 됩니다.\n이동하시겠습니까?", item);                
            }
        },
        fnPage() {
            this.acOrderReset();
            this.fnAlertClose();
            this.fnListSelect(this.isRoute);
            this.$router.push(`/${this.isRoute}`);
        },
        async fnContinue(){            
            const array = ['/main','/analyze','/subscribe','/mypage','/mypage#noback','/'];
            this.$router.afterEach(async (to, from) => {
                this.acCtaBtnState(false);
                const cont = array.find(e =>{    
                    if(e === to.fullPath) return e;
                });
                if(!cont || cont === undefined) {
                    this.isCart = false;
                    return false;
                }               
                const result = await this.acOrderDeterminedList();
                if(result){
                    if(result.id > 0){
                        this.acCtaBtnState(true);
                        this.isCart = true;          
                    }         
                    else{
                        this.isCart = false;
                    }    
                }
                else{
                    this.isCart = false;
                }                                       
            }); 
        }
    },
    mixins: [alertBase]
}
</script>

import store from "../../index.js";
import api from "../../../utils/api.js";
import CouponTicketAcConstant from '@/mixins/coupon/const/couponTicketAcConstant.js';

export const modules = {
    store,
};

export const state = {

};

export const mutations = {  

};

export const actions = {
    async acCouponTicket({ commit }){        
        const url = CouponTicketAcConstant.GET_COUPON_TICKET;
        try {
            const res = await api.get(url);
            return res.data?.code === 0 && res.data?.data?.status;
        }
        catch (error) {
            console.log(commit);
            return false;
        }   
    },     
    async acCouponTicketConfirm({ commit }){        
        const url = CouponTicketAcConstant.GET_COUPON_TICKET_CONFIRM;
        try {
            const res = await api.get(url);
            return res.data.message;
        }
        catch (error) {
            console.log(commit);
            return error.response.data.message;
        }   
    },  
};

export const getters = {
    geCodeFail: state => {
        switch(state._error.code){

        }    
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
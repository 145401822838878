

const previewRouter = [
    {
        path: "/preview/landing",
        name: "preview-premium-landing",
        component: () => import("../views/preview/HouseOwnerView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/preview/realtorLanding",
        name: "preview-realtor-landing",
        component: () => import("../views/preview/RealtorView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/preview/report",
        name: "main-indexview",
        component: () => import("../views/preview/MainIndexPreviewView.vue"),
    },    

    //관리자 리포트 미리보기
    {
        path: "/preview/report-manager",
        name: "preview-report-manager",
        component: () => import("../views/preview/ManagerReportView.vue"),
    },    
    {
        path: "/preview/subscribe-manager",
        name: "preview-subscribe-manager",
        component: () => import("../views/preview/ManagerSubscribeView.vue"),
    },   

    //구독 컨텐츠 결제전 미리보기
    {
        path: "/preview/subscribe-report",
        name: "preview-subscribe-report",
        component: () => import("../views/preview/SubscribeReportPreviewView.vue"),
    },     
];


export default previewRouter;



//---------------------
// 탈퇴
//---------------------
const withdrawRouter = [
    {
        path: "/withdraw/possible",
        name: "withdraw-possible",
        component: () => import("../views/withdraw/AnalyzePossibleWithDraw.vue"),
    },
    {
        path: "/withdraw/reason",
        name: "withdraw-reason",
        component: () => import("../views/withdraw/AnalyzeReasonWithDraw.vue"),
    },
    {
        path: "/withdraw/complete",
        name: "withdraw-complete",
        component: () => import("../views/withdraw/AnalyzeCompleteWithDraw.vue"),
    },
    {
        path: "/withdraw/alert",
        name: "withdraw-alert",
        component: () => import("../views/withdraw/AnalyzeWithDrawAlert.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
    {
        path: "/withdraw/unable",
        name: "withdraw-unable",
        component: () => import("../views/withdraw/AnalyzeUnableWithDraw.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
];


export default withdrawRouter;

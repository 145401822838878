<template>
    <div class="loading">
        <div class="spinner"></div>
    </div>
</template>

<script>
export default {
    name: 'LoadingPopup',
    components: {
    }
}
</script>
<style scoped>
.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0,0,0,0.25); */
    z-index: 100000;
}
@keyframes spinner {
    to {transform: rotate(360deg);}
}
.spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.spinner:before {
    content: '';
    position: relative;
    left: 50%;
    display: inline-block;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    border-radius: 50%;
    border-top: 5px solid #6beef7;
    border-left: 5px solid #91f8ff;
    border-bottom: 1px solid #00f3ff;
    border-right: 5px solid transparent;
    animation: spinner .6s linear infinite;
}
</style>